import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { CleandbService } from '../../services/cleandb/cleandb.service';
import Swal from 'sweetalert2';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit, AfterViewInit {
    public allClients: any[] = [];
    private clientsLoaded: boolean = false;
    @ViewChildren('ngForRendred') ngForList: QueryList<any>;
    public email: string = '';

    constructor(
        private clientService: ClientService,
        private cleandbService: CleandbService
    ) {}

    ngOnInit() {
        mApp.blockPage();

        $('#m_select2_3').select2({
            allowClear: false,
            width: '100%',
            minimumResultsForSearch: Infinity,
        });

        this.getAllClients();
    }

    ngAfterViewInit() {
        this.ngForList.changes.subscribe((t) => {
            this.ngForRendredCallback();
        });
    }

    ngForRendredCallback() {
        if (!this.clientsLoaded) {
            $('#m_select2_2').select2({
                placeholder: 'Select client',
                allowClear: true,
                width: '100%',
            });
            $('#m_select2_2').val(null).trigger('change'); // this will set selection to first item
        }

        $('#m_select2_2').on('select2:select', function (e) {
            // var data = e.params.data;
        });
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                mApp.unblockPage();
            }
        );
    }

    submit() {
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr(
            'disabled',
            true
        );
        let client_name = $('#m_select2_2').select2('data')[0].text;
        if (client_name[0] === ' ') {
            client_name = client_name.substring(1);
        }

        if (client_name[client_name.length - 1] === ' ') {
            client_name = client_name.substring(0, client_name.length - 1);
        }

        let selected_period = $('#m_select2_3').select2('data')[0].id;

        // console.log(client_name);
        // console.log(selected_period);
        // console.log(this.email);

        // post the csv report
        this.cleandbService
            .postCreateCSVReport(client_name, selected_period, this.email)
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancel();
                    btn.removeClass(
                        'm-loader m-loader--right m-loader--light'
                    ).attr('disabled', false);
                },
                (err) => {
                    Swal('Generating Report', 'You will receive an email with the reports during the next few hours', 'info');
                    btn.removeClass(
                        'm-loader m-loader--right m-loader--light'
                    ).attr('disabled', false);
                }
            );
    }

    cancel() {
        $('#m_select2_2').val(null).trigger('change'); // this will set selection to no item
    }
}
