import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../services/utils/constants.service';
import { ClientService } from '../../services/client/client.service';
import { RoomService } from '../../services/room/room.service';
import { CleandbService } from '../../services/cleandb/cleandb.service';

declare var $: any;

@Component({
    selector: 'app-replace-device',
    templateUrl: './replace-device.component.html',
    styleUrls: ['./replace-device.component.css'],
})
export class ReplaceDeviceComponent implements OnInit {
    public allRooms: any[] = [];
    public allRoomsNames: any[] = [];
    public allApartmentsNames: any[] = [];
    public allClients: any[] = [];
    public allClientsNames: string[] = [];
    public new_mac_address: string = '';
    public new_name: string = '';

    constructor(
        private constantsService: ConstantsService,
        private clientService: ClientService,
        private roomService: RoomService,
        private cleandbService: CleandbService
    ) {}

    ngOnInit() {
        let self = this;
        // basic
        $('#m_select2_1').select2({
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_1').on('select2:select', function (e) {
            // Do something
            var apartment_name = e.params.data.text;
            self.populateAllRooms(apartment_name);
        });

        $('#m_select2_2').select2({
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_2').on('select2:select', function (e) {
            // Do something
            var room_name = e.params.data.text;
            console.log('selected room name = ' + room_name);
        });

        $('#m_select2_4').select2({
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_4').on('select2:select', function (e) {
            // Do something
            var client_name = e.params.data.text;
            console.log('selected client name = ' + client_name);
            self.populateAllApartments();
        });

        this.getAllClients();
    }

    populateAllRooms(apartment_name: string): void {
        this.allRoomsNames = [];
        $('#m_select2_2').val(null).trigger('change');
        $('#m_select2_2').empty();

        let selected_client = '';

        if ($('#m_select2_4').select2('data').length > 0) {
            selected_client = $('#m_select2_4').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let room_name = this.allRooms[i].name;
            let current_apartment_name = this.allRooms[i].apartmentName;
            if (
                this.allRooms[i].client == selected_client &&
                current_apartment_name == apartment_name &&
                this.allRoomsNames.indexOf(room_name) == -1
            ) {
                this.allRoomsNames.push(room_name);
            }
        }

        let self = this;
        $('#m_select2_2').select2({
            data: self.allRoomsNames,
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });
    }

    populateAllApartments(): void {
        this.allApartmentsNames = [];
        $('#m_select2_1').val(null).trigger('change');
        $('#m_select2_1').empty();

        let selected_client = '';

        if ($('#m_select2_4').select2('data').length > 0) {
            selected_client = $('#m_select2_4').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let apartment_name = this.allRooms[i].apartmentName;
            // we have to check first if room belongs to selected client
            if (
                this.allRooms[i].client == selected_client &&
                this.allApartmentsNames.indexOf(apartment_name) == -1
            ) {
                this.allApartmentsNames.push(apartment_name);
            }
        }

        let self = this;
        $('#m_select2_1').select2({
            data: self.allApartmentsNames,
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        if ($('#m_select2_1').select2('data').length > 0) {
            let selected_apartment = $('#m_select2_1').select2('data')[0].text;
            if (selected_apartment) {
                this.populateAllRooms(selected_apartment);
            }
        } else {
            this.populateAllRooms('');
        }
    }

    populateAlllClients(): void {
        this.allClientsNames = [];
        $('#m_select2_4').val(null).trigger('change');
        $('#m_select2_4').empty();

        for (let i = 0; i < this.allClients.length; i++) {
            let client_name = this.allClients[i].clientName;
            if (this.allClientsNames.indexOf(client_name) == -1) {
                this.allClientsNames.push(client_name);
            }
        }

        let self = this;
        $('#m_select2_4').select2({
            data: self.allClientsNames,
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        this.getAllRooms();
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                this.populateAlllClients();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                this.populateAlllClients();
                console.log('clients empty');
            }
        );
    }

    getAllRooms(): void {
        this.roomService.getAllRooms().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allRooms = response.value;
                this.populateAllApartments();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allRooms = [];
                this.allApartmentsNames = [];
                this.populateAllApartments();
            }
        );
    }

    cancel() {
        $('#m_select2_4').val(null).trigger('change');
        $('#m_select2_4').empty();
        $('#m_select2_1').val(null).trigger('change');
        $('#m_select2_1').empty();
        $('#m_select2_2').val(null).trigger('change');
        $('#m_select2_2').empty();
        this.new_mac_address = '';
        this.new_name = '';
        this.getAllClients();
    }

    submit() {
        if (
            $('#m_select2_4').select2('data').length <= 0 ||
            $('#m_select2_1').select2('data').length <= 0 ||
            $('#m_select2_2').select2('data').length <= 0
        ) {
            return;
        }
        let client_name = $('#m_select2_4').select2('data')[0].text;
        let apartment = $('#m_select2_1').select2('data')[0].text;
        let room = $('#m_select2_2').select2('data')[0].text;
        this.new_mac_address = this.new_mac_address.replace(/\s/g, '');
        this.new_name = this.new_name.replace(/\s/g, '');
        console.log('Client = ' + client_name);
        console.log('Apartment = ' + apartment);
        console.log('Room = ' + room);
        console.log('Mac address = ' + this.new_mac_address);
        console.log('Name = ' + this.new_name);

        this.cleandbService
            .putReplaceDevice(
                apartment,
                room,
                client_name,
                this.new_mac_address,
                this.new_name
            )
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                },
                (err) => {
                    // if reached here, it means the response is error
                }
            );
    }
}
