import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root',
})
export class DailyReadingsService {
    constructor(
      private http: Http,
        private tokenService: TokenService,
        private constnatsService: ConstantsService
    ) {}

    getAllMSCReadings(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                const date = new Date();
                let zone: number = date.getTimezoneOffset() * -1;

                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }

                var client_query = '';
                if (client) {
                    client_query = '&client_name=' + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/dailyreadings/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&timezone=' +
                            zone.toString() +
                            '&period=' +
                            period.toString() +
                            '&chart=true' +
                            client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMscReading(id: string): Observable<any> {
      var self = this;
      var chainedObservable$ = this.tokenService.getToken().pipe(
          mergeMap(function (token_response) {
              let headers = new Headers();
              headers.append(
                  'Content-Type',
                  'application/x-www-form-urlencoded'
              );
              headers.append('Accept', 'application/json');
              if (
                  !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
              ) {
                  headers.append('rejectUnauthorized', 'false');
              }
              if (token_response) {
                  let access_token = token_response.access_token;
                  headers.append('Authorization', 'Bearer ' + access_token);
              }
              let options = new RequestOptions({ headers: headers });
              return self.http
                  .delete(
                      self.constnatsService.API_CORE_URL +
                          '/api/dailyreadings/' +
                          id,
                      options
                  )
                  .pipe(map((res) => res.json()));
          })
      );

      return chainedObservable$;
  }

  deleteMscReadings(id: Array<string>): Observable<any> {
      var self = this;
      var chainedObservable$ = this.tokenService.getToken().pipe(
          mergeMap(function (token_response) {
              let formData: FormData = new FormData();
              for (let i = 0; i < id.length; i++) {
                  formData.append('id[]', id[i]);
              }
              let headers = new Headers();
              headers.append('Accept', 'application/json');
              if (
                  !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
              ) {
                  headers.append('rejectUnauthorized', 'false');
              }
              if (token_response) {
                  let access_token = token_response.access_token;
                  headers.append('Authorization', 'Bearer ' + access_token);
              }
              let options = new RequestOptions({
                  headers: headers,
                  body: formData,
              });
              return self.http
                  .delete(
                      self.constnatsService.API_CORE_URL +
                          '/api/dailyreadings/',
                      options
                  )
                  .pipe(map((res) => res.json()));
          })
      );

      return chainedObservable$;
  }
}
