import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: Http, private tokenService: TokenService,
        private constnatsService: ConstantsService) { }

    postUser(user: any, file: File, send_sms: boolean, send_email: boolean): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                if (file) {
                    formData.append('file', file, file.name);
                }
                formData.append('name', user.name);
                formData.append('username', user.username);
                formData.append('password', user.password);
                formData.append('email', user.email);
                formData.append('role', user.role);
                formData.append('profile', user.profile);
                formData.append('phone', user.phone);
                formData.append('country', user.country);
                formData.append('countryIso', user.countryIso);
                if (user.client) {
                    formData.append('client', user.client);
                }
                formData.append('apartments', user.apartments);
                formData.append('send_sms', String(send_sms));
                formData.append('send_email', String(send_email));
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/user/', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    putUser(user: any, file: File): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                if (file) {
                    formData.append('file', file, file.name);
                }
                formData.append('id', user.id);
                formData.append('name', user.name);
                formData.append('username', user.username);
                formData.append('password', user.password);
                formData.append('email', user.email);
                formData.append('role', user.role);
                formData.append('profile', user.profile);
                formData.append('phone', user.phone);
                formData.append('country', user.country);
                formData.append('countryIso', user.countryIso);
                if (user.client) {
                    formData.append('client', user.client);
                }
                if (user.apartments.length > 0) {
                    for (let i = 0; i < user.apartments.length; i++) {
                        formData.append('apartments[]', user.apartments[i]);
                    }
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.put(self.constnatsService.API_CORE_URL + '/api/user/' + user.id, formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getAllUsers(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/user/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getMe(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/user/me/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteUser(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/user/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteUsers(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData
                });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/user/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getUser(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/user/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    forget_password_request(email: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                formData.append('email', email);

                let headers = new Headers();
                headers.append("Accept", "application/json");
                headers.append("Cache-Control", "no-cache");
                headers.append("Access-Control-Allow-Origin", "*");
                headers.append("Access-Control-Allow-Methods", "GET,POST,OPTIONS,DELETE,PUT");
                headers.append("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token");
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/user/forget_password_request', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    verify_reset_password(id: string, token: string, new_password: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                formData.append('id', id);
                formData.append('token', token);
                formData.append('new_password', new_password);

                let headers = new Headers();
                headers.append("Accept", "application/json");
                headers.append("Cache-Control", "no-cache");
                headers.append("Access-Control-Allow-Origin", "*");
                headers.append("Access-Control-Allow-Methods", "GET,POST,OPTIONS,DELETE,PUT");
                headers.append("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token");
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/user/verify_reset_password', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }
}
