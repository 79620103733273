import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConstantsService } from '../../services/utils/constants.service';
import { ClientService } from '../../services/client/client.service';
import { ValidationService } from '../../services/utils/validation.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-client-add',
    templateUrl: './client-add.component.html',
    styleUrls: ['./client-add.component.css']
})
export class ClientAddComponent implements OnInit {
    public clientName: string = '';
    public clientId: string = '';
    public clientSecret: string = '';
    public repeatClientSecret: string = '';
    public clientMaxUsers: string = '10';
    public clientLoggingInterval: string = '1825';

    private readonly SPAN_NORMAL_NAME: string = 'Enter client name';
    private readonly SPAN_DANGER_NAME: string = 'Client name canot be empty!';
    private readonly SPAN_NORMAL_ID: string = 'Enter client ID';
    private readonly SPAN_DANGER_ID: string = 'Client ID canot be empty!';
    private readonly SPAN_NORMAL_SECRET: string = 'Enter secret';
    private readonly SPAN_NORMAL_REPEAT_SECRET: string = 'Confirm secret';
    private readonly SPAN_DANGER_SECRET: string = 'Secret should match!';
    private readonly SPAN_NORMAL_EXPIRY_DATE: string = 'Select expiry date';
    private readonly SPAN_DANGER_EXPIRY_DATE: string = 'Date format is not correct!';

    public span_client_name: string;
    public span_client_id: string;
    public span_client_secret: string;
    public repeat_client_secret_span: string;
    public span_repeat_client_secret: string;
    public span_expiry_date: string;

    constructor(private constantsService: ConstantsService, private clientService: ClientService,
        private validationService: ValidationService) { }

    ngOnInit() {
        // minimum setup
        $('#m_touchspin_1').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 10,
            max: 10000,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        // minimum setup
        $('#m_touchspin_2').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 30,
            max: 10000,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        // input group demo
        $('#m_datetimepicker_2').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'top-right',
            format: 'yyyy/mm/dd',
            minView: 2
        });

        // basic
        $('#m_select2_1').select2({
            placeholder: "Select account",
            allowClear: false,
            width: '100%'
        });

        $('#m_select2_2').select2({
            placeholder: "Select status",
            allowClear: false,
            width: '100%'
        });
        this.clearWarnings();
    }

    private clearWarnings(): void {
        this.span_client_name = this.SPAN_NORMAL_NAME;
        this.span_client_id = this.SPAN_NORMAL_ID;
        this.span_client_secret = this.SPAN_NORMAL_SECRET;
        this.span_repeat_client_secret = this.SPAN_NORMAL_REPEAT_SECRET;
        this.span_expiry_date = this.SPAN_NORMAL_EXPIRY_DATE;

        $('#client_name_span').removeClass();
        $('#client_name_span').addClass('m-form__help');
        $('#clientNamelabel').removeClass();

        $('#client_id_span').removeClass();
        $('#client_id_span').addClass('m-form__help');
        $('#clientIdlabel').removeClass();

        $('#client_secret_span').removeClass();
        $('#client_secret_span').addClass('m-form__help');
        $('#clientSecretlabel').removeClass();

        $('#repeat_client_secret_span').removeClass();
        $('#repeat_client_secret_span').addClass('m-form__help');
        $('#repeatClientSecretlabel').removeClass();

        $('#expiry_date_span').removeClass();
        $('#expiry_date_span').addClass('m-form__help');
        $('#expiryDateLabel').removeClass();
    }

    private clientNameWarnings(): void {
        this.span_client_name = this.SPAN_DANGER_NAME;
        $('#client_name_span').removeClass();
        $('#client_name_span').addClass('m-form__help m--font-danger');
        $('#clientNamelabel').removeClass();
        $('#clientNamelabel').addClass('m--font-danger');
    }

    private clientIdWarnings(): void {
        this.span_client_id = this.SPAN_DANGER_ID;
        $('#client_id_span').removeClass();
        $('#client_id_span').addClass('m-form__help m--font-danger');
        $('#clientIdlabel').removeClass();
        $('#clientIdlabel').addClass('m--font-danger');
    }

    private clientSecretWarnings(): void {
        this.span_client_secret = this.SPAN_DANGER_SECRET;
        $('#client_secret_span').removeClass();
        $('#client_secret_span').addClass('m-form__help m--font-danger');
        $('#clientSecretlabel').removeClass();
        $('#clientSecretlabel').addClass('m--font-danger');
    }

    private clientRepeatSecretWarnings(): void {
        this.span_repeat_client_secret = this.SPAN_DANGER_SECRET;
        $('#repeat_client_secret_span').removeClass();
        $('#repeat_client_secret_span').addClass('m-form__help m--font-danger');
        $('#repeatClientSecretlabel').removeClass();
        $('#repeatClientSecretlabel').addClass('m--font-danger');
    }

    private clientExpiryDateWarnings(): void {
        this.span_expiry_date = this.SPAN_DANGER_EXPIRY_DATE;
        $('#expiry_date_span').removeClass();
        $('#expiry_date_span').addClass('m-form__help m--font-danger');
        $('#expiryDateLabel').removeClass();
        $('#expiryDateLabel').addClass('m--font-danger');
    }

    private validate(client: any): boolean {
        let validation: boolean = true;
        // Client Name
        if (!(this.validationService.validateNonEmpty(client.ClientName))) {
            validation = false;
            this.clientNameWarnings();
        }
        // Client Id
        if (!(this.validationService.validateNonEmpty(client.ClientId)) ||
            !(this.validationService.validateWhiteSpace(client.ClientId))) {
            validation = false;
            this.clientIdWarnings();
        }
        // Client Secret
        if (!(this.validationService.validateNonEmpty(this.clientSecret)) ||
            !(this.validationService.validateWhiteSpace(this.clientSecret)) ||
            (this.clientSecret != this.repeatClientSecret)) {
            validation = false;
            this.clientSecretWarnings();
            this.clientRepeatSecretWarnings();
        }
        // Algorithm Date
        if (!(this.validationService.validateDateMoment(client.expiryDate)) ||
            ($('#m_datetimepicker_2').val() == '')) {
            validation = false;
            this.clientExpiryDateWarnings();
        }
        return validation;
    }

    public cancel(): void {
        this.clientName = '';
        this.clientId = '';
        this.clientSecret = '';
        this.repeatClientSecret = '';
        this.clientMaxUsers = '10';
        this.clientLoggingInterval = '1825';
        $('#m_datetimepicker_2').val("").datepicker("update");
        // $('#m_select2_1').val(null).trigger('change'); // this will remove the whole selection
        $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item
        // $('#m_select2_2').val(null).trigger('change'); // this will remove the whole selection
        $('#m_select2_2').val(0).trigger('change'); // this will set selection to first item

        this.clearWarnings();
    }

    public submit(): void {
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        let account_type_data = $('#m_select2_1').select2('data')[0];
        let account_type = '';
        if (account_type_data.id == 0) {
            account_type = this.constantsService.SUPER_ACCOUNT;
        } else if (account_type_data.id == 1) {
            account_type = this.constantsService.CLIENT_ACCOUNT;
        }

        let account_status_data = $('#m_select2_2').select2('data')[0];
        let account_status = '';
        if (account_status_data.id == 0) {
            account_status = this.constantsService.ACTIVE_STATUS;
        } else if (account_status_data.id == 1) {
            account_status = this.constantsService.SUSPENDED_STATUS;
        }

        this.clientMaxUsers = $('#m_touchspin_1').val();
        this.clientLoggingInterval = $('#m_touchspin_2').val();
        let date_string = $("#m_datetimepicker_2").data("datetimepicker").getDate();
        let date = moment(date_string).toISOString();
        // date.format("YYYY-MM-DD HH:mm:ss");

        let client = {
            'ClientName': this.clientName,
            'ClientId': this.clientId,
            'accountType': account_type,
            'status': account_status,
            'maxUsers': this.clientMaxUsers,
            'loggingIntervalDays': this.clientLoggingInterval,
            'expiryDate': date,
            'clientSecret': this.clientSecret
        }

        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

        if (this.validate(client)) {
            // post the client
            this.clientService.postClient(client).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancel();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }
}
