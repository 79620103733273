import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    constructor(
        private http: Http,
        private tokenService: TokenService,
        private constnatsService: ConstantsService
    ) {}

    getAllRoomSensorsLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                const date = new Date();
                let zone: number = date.getTimezoneOffset() * -1;
                
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/roomsensorlog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&timezone=' +
                            zone.toString() +
                            '&period=' +
                            period.toString() +
                            '&chart=true' + client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getAllMSCLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                const date = new Date();
                let zone: number = date.getTimezoneOffset() * -1;

                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }

                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/modosmartcontrollerlog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&timezone=' +
                            zone.toString() +
                            '&period=' +
                            period.toString() +
                            '&chart=true' + client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getAllMSCBoilerLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscBoilerlog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&period=' +
                            period.toString() + client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getAllMSCAirDuctLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscAirDuctlog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&period=' +
                            period.toString()+ client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getAllMSCFanCoilLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/fancoillog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&period=' +
                            period.toString() + client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getAllMSCIRBoilerLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscirboilerlog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&period=' +
                            period.toString() + client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getAllAcSwitchLog(
        apartment: string,
        room: string,
        period: number,
        client: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                var client_query = "";
                if (client) {
                    client_query = "&client_name=" + client;
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .get(
                        self.constnatsService.API_CORE_URL +
                            '/api/acswitchlog/period?apartment=' +
                            apartment +
                            '&room=' +
                            room +
                            '&period=' +
                            period.toString()+ client_query,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteRoomSensorLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/roomsensorlog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteRoomSensorLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/roomsensorlog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMscLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/modosmartcontrollerlog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMscLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/modosmartcontrollerlog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteAcSwitchLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/acswitchlog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteAcSwitchLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/acswitchlog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCAirDuctLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscairductlog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCAirDuctLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscairductlog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCBoilerLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscboilerlog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCBoilerLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscboilerlog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCFanCoilLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/fancoillog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCFanCoilLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/fancoillog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCIrBoilerLog(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscirboilerlog/' +
                            id,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    deleteMSCIrBoilerLogs(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData,
                });
                return self.http
                    .delete(
                        self.constnatsService.API_CORE_URL +
                            '/api/mscirboilerlog/',
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }
}
