import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class GatewayService {

    constructor(private http: Http, private tokenService: TokenService,
        private constnatsService: ConstantsService) { }

    getAllGateways(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/gateway/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getGatewayByName(name: string, client_name: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                var client_query = "";
                if (client_name) {
                    client_query = "?client_name=" + client_name;
                }
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/gateway/name/' + name + client_query,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteGatewayId(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/gateway/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteGatewayMacAddress(mac_address: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/gateway/mac_address' + mac_address,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteGateways(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData
                });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/gateway/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }
}
