import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { RoomService } from '../../services/room/room.service';
import { ConstantsService } from '../../services/utils/constants.service';
import { DeviceService } from '../../services/device/device.service';
import { GatewayService } from '../../services/gateway/gateway.service';
import { ClientService } from '../../services/client/client.service';
import { ApartmentService } from '../../services/apartment/apartment.service';
import Swal from 'sweetalert2';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-automations',
    templateUrl: './automations.component.html',
    styleUrls: ['./automations.component.css'],
})
export class AutomationsComponent implements OnInit {
    public allRooms: any[] = [];

    public allRoomsNames: any[] = [];
    public allApartmentsNames: any[] = [];
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public parameters: any = {};
    public allDevices: any[] = [];
    public selected_mac_address: string = '';
    public selected_device: any = {}
    public is_smart: boolean = false;
    public gateway: any = {
        mac_address: ''
    };
    public allClients: any[] = [];
    public allClientsNames: string[] = [];
    public finalReadings: any[] = [];

    constructor(
        private roomService: RoomService,
        private userService: UserService,
        private deviceService: DeviceService,
        private gatewayService: GatewayService,
        private constantsService: ConstantsService,
        private changeDetection: ChangeDetectorRef,
        private clientService: ClientService,
        private apartmentService: ApartmentService,
    ) {
        this.cancel();
    }

    ngOnInit() {
        let self = this;
        // basic
        $('#m_select2_1').select2({
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_1').on('select2:select', function (e) {
            // Do something
            var apartment_name = e.params.data.text;
            self.populateAllRooms(apartment_name);
        });

        $('#m_select2_2').select2({
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_2').on('select2:select', function (e) {
            // Do something
            var room_name = e.params.data.text;
            self.read_parameters();
        });

        $('#m_select2_3').select2({
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_3').on('select2:select', function (e) {
            // Do something
            var client_name = e.params.data.text;
            self.populateAllApartments();
        });

        // basic
        $('#m_select2_4').select2({
            placeholder: 'Select AC mode',
            allowClear: false,
            width: '100%',
        });

        $('#m_touchspin_1').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 23,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_2').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 59,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_3').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 23,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_4').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 59,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_5').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 360,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_6').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 360,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_7').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 10,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_8').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 0,
            max: 360,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        $('#m_touchspin_9').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 18,
            max: 27,
            step: 1,
            decimals: 0,
            boostat: 20,
            maxboostedstep: 10,
        });

        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                    this.getAllClients();
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                    this.getAllClients();
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                    this.getAllDevices();
                }
            },
            (err) => {
                // if reached here, it means the response is error
            }
        );
    }

    getAllDevices(): void {
        this.deviceService.getAllDevices().subscribe(
            (response) => {
                this.allDevices = response.value;
                this.getAllRooms();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allDevices = [];
                this.getAllRooms();
            }
        );
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                this.populateAlllClients();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                this.populateAlllClients();
            }
        );
    }

    getLocalDevices(mac_addres: string): void {
        mApp.blockPage();
        this.deviceService.getLocalDevice(mac_addres).subscribe(
            (response) => {
                mApp.unblockPage();
                let response_str = response;
                if (!response_str) {
                    this.cancel();
                } else {
                    this.parameters = JSON.parse(response);
                    // console.log(this.parameters);
                    this.changeDetection.detectChanges();
                    this.force_update_ui();
                }
            },
            (err) => {
                // if reached here, it means the response is error
                mApp.unblockPage();
                this.cancel();
                Swal(
                    'Oops...',
                    'Gateway is not responding, looks like it\'s offline',
                    'error'
                );
            }
        );
    }

    getgateway(name: string): void {
        var selected_client = null;
        if (this.isDeveloper || this.isSuper)
        {
            if ($('#m_select2_3').select2('data').length > 0)
            {
                selected_client = $('#m_select2_3').select2('data')[0].text;
            }
        }
        this.gatewayService.getGatewayByName(name, selected_client).subscribe(
            (response) => {
                this.gateway = response.value;
            },
            (err) => {
                // if reached here, it means the response is error
                this.gateway = {
                    mac_address: ''
                };
            }
        );
    }

    getAllRooms(): void {
        this.roomService.getAllRooms().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allRooms = response.value;
                this.populateAllApartments();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allRooms = [];
                this.allApartmentsNames = [];
                this.populateAllApartments();
            }
        );
    }

    populateAllRooms(apartment_name: string): void {
        this.allRoomsNames = [];
        $('#m_select2_2').val(null).trigger('change');
        $('#m_select2_2').empty();

        let selected_client = '';

        if ($('#m_select2_3').select2('data').length > 0) {
            selected_client = $('#m_select2_3').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let room_name = this.allRooms[i].name;
            let current_apartment_name = this.allRooms[i].apartmentName;

            if (this.isDeveloper || this.isSuper) {
                if (this.allRooms[i].client == selected_client && current_apartment_name == apartment_name &&
                    this.allRoomsNames.indexOf(room_name) == -1) {
                    this.allRoomsNames.push(room_name);
                }
            } else {
                if (current_apartment_name == apartment_name && this.allRoomsNames.indexOf(room_name) == -1) {
                    this.allRoomsNames.push(room_name);
                }
            }
        }

        let self = this;
        $('#m_select2_2').select2({
            data: self.allRoomsNames,
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });

        this.read_parameters();
    }

    populateAllApartments(): void {
        this.allApartmentsNames = [];
        $('#m_select2_1').val(null).trigger('change');
        $('#m_select2_1').empty();

        let selected_client= '';

        if  ($('#m_select2_3').select2('data').length > 0) {
            selected_client = $('#m_select2_3').select2('data')[0].text;
        }

        this.gateway = {
            mac_adress: ''
        }
        this.selected_mac_address = '';
        this.cancel();

        for (let i = 0; i < this.allRooms.length; i++) {
            let apartment_name = this.allRooms[i].apartmentName;
            if (this.isDeveloper || this.isSuper) {
                // we have to check first if room belongs to selected client
                if (this.allRooms[i].client == selected_client && this.allApartmentsNames.indexOf(apartment_name) == -1) {
                    this.allApartmentsNames.push(apartment_name);
                }
            } else {
                if (this.allApartmentsNames.indexOf(apartment_name) == -1) {
                    this.allApartmentsNames.push(apartment_name);
                }
            }
        }

        let self = this;
        $('#m_select2_1').select2({
            data: self.allApartmentsNames,
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });
        if ($('#m_select2_1').select2('data').length > 0) {
            let selected_apartment = $('#m_select2_1').select2('data')[0].text;
            if (selected_apartment) {
                this.populateAllRooms(selected_apartment);
            }
        } else {
            this.populateAllRooms('');
        }
    }

    populateAlllClients(): void {
        this.allClientsNames = [];
        $('#m_select2_3').val(null).trigger('change');
        $('#m_select2_3').empty();

        for (let i = 0; i < this.allClients.length; i++) {
            let client_name = this.allClients[i].clientName;
            if (this.allClientsNames.indexOf(client_name) == -1) {
                this.allClientsNames.push(client_name);
            }
        }

        let self = this;
        $('#m_select2_3').select2({
            data: self.allClientsNames,
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        this.getAllDevices();
    }

    checkSmartToggle(event: any) {
        this.parameters.mode = event;
    }

    read_parameters() {
        let room_name = '';
        if ($('#m_select2_2').select2('data').length > 0)
        {
            room_name = $('#m_select2_2').select2('data')[0].text;
        }

        let selected_client= '';

        if  ($('#m_select2_3').select2('data').length > 0) {
            selected_client = $('#m_select2_3').select2('data')[0].text;
        }

        let apartment_name = '';
        if ($('#m_select2_1').select2('data').length > 0)
        {
            apartment_name = $('#m_select2_1').select2('data')[0].text;
        }

        var mac_address = "";
        var gateway_name = "";
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.isDeveloper || this.isSuper) {
                // we have to check first if room belongs to selected client
                if (this.allDevices[i].client == selected_client && this.allDevices[i].room == room_name && this.allDevices[i].device_type != "RoomSensor"
                    && this.allDevices[i].device_type != "SHELLY_WINDOW"
                    && this.allDevices[i].apartment == apartment_name
                ) {
                    mac_address = this.allDevices[i].mac_address;
                    gateway_name = this.allDevices[i].gateway;
                    break;
                }
            } else {
                if (this.allDevices[i].room == room_name && this.allDevices[i].device_type != "RoomSensor"
                    && this.allDevices[i].device_type != "SHELLY_WINDOW"
                    && this.allDevices[i].apartment == apartment_name
                ) {
                    mac_address = this.allDevices[i].mac_address;
                    gateway_name = this.allDevices[i].gateway;
                    break;
                }
            }
        }
        
        this.selected_mac_address = mac_address;

        if (mac_address) {
            this.parameters.macaddr = mac_address;

            this.getLocalDevices(mac_address);
        } else {
            this.cancel();
        }

        if (gateway_name) {
            this.getgateway(gateway_name);
        } else {
            this.gateway = {
                mac_address: ''
            }
        }
    }

    update_parameters(update_group: number) {
        // console.log(this.parameters);

        this.parameters.night_mode_start_time_h = $('#m_touchspin_1').val();
        this.parameters.night_mode_start_time_m = $('#m_touchspin_2').val();
        this.parameters.night_mode_stop_time_h = $('#m_touchspin_3').val();
        this.parameters.night_mode_stop_time_m = $('#m_touchspin_4').val();
        this.parameters.fan_timer_mins = $('#m_touchspin_5').val();
        this.parameters.temperature_offset_timer_mins = $('#m_touchspin_6').val();
        this.parameters.temperature_offset = $('#m_touchspin_7').val();
        this.parameters.off_timer_mins = $('#m_touchspin_8').val();
        this.parameters.ac_temp = $('#m_touchspin_9').val();

        mApp.blockPage();
        this.deviceService.postLocalDevice(this.parameters, update_group).subscribe(
            (response) => {
                mApp.unblockPage();
            },
            (err) => {
                mApp.unblockPage();
            }
        );
    }

    get_back_end_parameters() {
        let selected_client= null;

        if (this.isDeveloper || this.isSuper)
        {
            if  ($('#m_select2_3').select2('data').length > 0) {
                selected_client = $('#m_select2_3').select2('data')[0].text;
            }
        }

        mApp.blockPage();
        this.apartmentService.getProperties(selected_client).subscribe(
            (response) => {
                this.finalReadings = response.value;
                mApp.unblockPage();
                this.update_backend_ui();
            },
            (err) => {
                mApp.unblockPage();
            }
        );
    }

    cancel() {
        this.parameters = {
            "macaddr": this.selected_mac_address,
            "night_mode": 0,
            "night_mode_start_time_h": 0,
            "night_mode_start_time_m": 0,
            "night_mode_stop_time_h": 0,
            "night_mode_stop_time_m": 0,
            "mode": "manual",
            "skip_auto_fan": false,
            "fan_timer_mins": 0,
            "skip_changing_temperature": false,
            "temperature_offset_timer_mins": 0,
            "temperature_offset": 0,
            "allow_auto_off": false,
            "off_timer_mins": 0,
        }
        this.force_update_ui();
    }

    force_update_ui() {
        $('#m_touchspin_1').val(this.parameters.night_mode_start_time_h);
        $('#m_touchspin_2').val(this.parameters.night_mode_start_time_m);
        $('#m_touchspin_3').val(this.parameters.night_mode_stop_time_h);
        $('#m_touchspin_4').val(this.parameters.night_mode_stop_time_m);
        $('#m_touchspin_5').val(this.parameters.fan_timer_mins);
        $('#m_touchspin_6').val(this.parameters.temperature_offset_timer_mins);
        $('#m_touchspin_7').val(this.parameters.temperature_offset);
        $('#m_touchspin_8').val(this.parameters.off_timer_mins);
        $('#m_touchspin_9').val(this.parameters.ac_temp);

        this.is_smart = (this.parameters.mode == 'smart') ? true:false;

        if (this.parameters.ac_mode == 'cool') {
            $('#m_select2_4').val(0).trigger('change');
        } else if (this.parameters.ac_mode == 'heat') {
            $('#m_select2_4').val(1).trigger('change');
        }
    }

    update_backend_ui() {
        let apartment_name = '';
        if ($('#m_select2_1').select2('data').length > 0)
        {
            apartment_name = $('#m_select2_1').select2('data')[0].text;
        }

        let room_name = '';
        if ($('#m_select2_2').select2('data').length > 0)
        {
            room_name = $('#m_select2_2').select2('data')[0].text;
        }

        // console.log('Apartment: ' + apartment_name);
        // console.log('Room: ' + room_name);

        let found_apartment = false;
        let found_room = false;

        for (let i = 0; i < this.finalReadings.length; i++) {
            if (this.finalReadings[i].apartment.name == apartment_name) {
                found_apartment = true;
                for (let j = 0; i < this.finalReadings[i].rooms.length; j++) {
                    found_room = true;
                    if (this.finalReadings[i].rooms[j].name == room_name) {
                        if (this.finalReadings[i].rooms[j].modosmart_controller != null) {
                            // console.log("Found Modosmart Controller");
                            // console.log(this.finalReadings[i].rooms[j].modosmart_controller);
                            this.parameters.night_mode = (this.finalReadings[i].rooms[j].modosmart_controller.night_mode)? 1 : 0;
                            this.parameters.mode = this.finalReadings[i].rooms[j].modosmart_controller.ac_control;
                            this.is_smart = (this.finalReadings[i].rooms[j].modosmart_controller.ac_control == 'smart')? true : false;
                            this.parameters.ac_switch = (this.finalReadings[i].rooms[j].modosmart_controller.ac_mode == 'off')? false : true;
                            this.parameters.enabled = (this.finalReadings[i].rooms[j].modosmart_controller.enabled)? 1 : 0;
                            this.parameters.ac_temp = this.finalReadings[i].rooms[j].modosmart_controller.ac_temperature;
                            this.parameters.ac_mode = this.finalReadings[i].rooms[j].modosmart_controller.ac_mode;
                            if (this.finalReadings[i].rooms[j].modosmart_controller.ac_mode == 'cool') {
                                $('#m_select2_4').val(0).trigger('change');
                            } else if (this.finalReadings[i].rooms[j].modosmart_controller.ac_mode == 'heat') {
                                $('#m_select2_4').val(1).trigger('change');
                            }
                            break;
                        } else if (this.finalReadings[i].rooms[j].msc_boiler != null) {
                            // console.log("Found MSC Boiler");
                            // console.log(this.finalReadings[i].rooms[j].msc_boiler);
                            this.parameters.night_mode = (this.finalReadings[i].rooms[j].msc_boiler.night_mode)? 1 : 0;
                            this.parameters.mode = this.finalReadings[i].rooms[j].msc_boiler.boiler_control;
                            this.is_smart = (this.finalReadings[i].rooms[j].msc_boiler.boiler_control == 'smart')? true : false;
                            this.parameters.ac_switch = this.finalReadings[i].rooms[j].msc_boiler.boiler_state;
                            this.parameters.enabled = (this.finalReadings[i].rooms[j].msc_boiler.enabled)? 1 : 0;
                            this.parameters.ac_temp = this.finalReadings[i].rooms[j].msc_boiler.boiler_temperature;
                            this.parameters.ac_mode = this.finalReadings[i].rooms[j].msc_boiler.ac_mode;
                            break;
                        } else if (this.finalReadings[i].rooms[j].fan_coil != null) {
                            // console.log("Found Fan Coil");
                            // console.log(this.finalReadings[i].rooms[j].fan_coil);
                            this.parameters.night_mode = (this.finalReadings[i].rooms[j].fan_coil.night_mode)? 1 : 0;
                            this.parameters.mode = this.finalReadings[i].rooms[j].fan_coil.coil_contol;
                            this.is_smart = (this.finalReadings[i].rooms[j].fan_coil.coil_contol == 'smart')? true : false;
                            this.parameters.ac_switch = this.finalReadings[i].rooms[j].fan_coil.fan_coil_state;
                            this.parameters.enabled = (this.finalReadings[i].rooms[j].fan_coil.enabled)? 1 : 0;
                            this.parameters.ac_temp = this.finalReadings[i].rooms[j].fan_coil.coil_temperature;
                            this.parameters.ac_mode = this.finalReadings[i].rooms[j].fan_coil.coil_mode;
                            if (this.finalReadings[i].rooms[j].fan_coil.coil_mode == 'cool') {
                                $('#m_select2_4').val(0).trigger('change');
                            } else if (this.finalReadings[i].rooms[j].fan_coil.coil_mode == 'heat') {
                                $('#m_select2_4').val(1).trigger('change');
                            }
                            break;
                        } else if (this.finalReadings[i].rooms[j].msc_air_duct != null) {
                            // console.log("Found MSC air duct");
                            // console.log(this.finalReadings[i].rooms[j].msc_air_duct);
                            this.parameters.night_mode = (this.finalReadings[i].rooms[j].msc_air_duct.night_mode)? 1 : 0;
                            this.parameters.mode = this.finalReadings[i].rooms[j].msc_air_duct.air_duct_control;
                            this.is_smart = (this.finalReadings[i].rooms[j].msc_air_duct.air_duct_control == 'smart')? true : false;
                            this.parameters.ac_switch = this.finalReadings[i].rooms[j].msc_air_duct.air_duct_state;
                            this.parameters.enabled = (this.finalReadings[i].rooms[j].msc_air_duct.enabled)? 1 : 0;
                            this.parameters.ac_temp = '';
                            break;
                        } 
                    }

                    if (found_room)
                    {
                        break;
                    }
                }
            }

            if (found_apartment)
            {
                break;
            }
        }
        this.force_update_ui();
        this.changeDetection.detectChanges();
    }
}
