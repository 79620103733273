import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';
import { timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CleandbService {
    constructor(
        private http: Http,
        private tokenService: TokenService,
        private constnatsService: ConstantsService
    ) {}

    postBroadcastMode(client_id: string, mode: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                if (client_id) {
                    formData.append('client_id', client_id);
                }
                formData.append('mode', mode);
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/broadcast_mode/',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    postBroadcastNightMode(
        client_id: string,
        night_mode: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                if (client_id) {
                    formData.append('client_id', client_id);
                }
                formData.append('night_mode', night_mode);
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/broadcast_night_mode/',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    postRebootMSC(client: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                if (client) {
                    formData.append('client', client);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/reboot_msc/',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    postRestartGateway(client: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                if (client) {
                    formData.append('client', client);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/restart_gateway/',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    postMSCTimeout(client_id: string, timeout: number): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                if (client_id) {
                    formData.append('client_id', client_id);
                }
                formData.append('timeout', timeout.toString());
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/broadcast_gw_msc_timeout/',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    postRefresh(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/update_port_status',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    getProperties(client_name: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                );
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });

                var url =
                    self.constnatsService.API_CORE_URL +
                    '/api/cleandb/client_properties';
                if (client_name) {
                    url = url + '?client_name=' + client_name;
                }
                return self.http
                    .get(url, options)
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    putReplaceDevice(
        apartment: string,
        room: string,
        client: string,
        new_mac_address: string,
        new_name: string
    ): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                formData.append('client', client);
                formData.append('apartment', apartment);
                formData.append('room', room);
                formData.append('new_mac_address', new_mac_address);
                formData.append('new_name', new_name);
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .put(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/replace_device',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }

    postCreateCSVReport(client_name: string, period: number, target_email: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let formData: FormData = new FormData();
                if (client_name) {
                    formData.append('client_name', client_name);
                }
                formData.append('period', period.toString());
                if (target_email) {
                    formData.append('target_email', target_email);
                }
                formData.append('target_email_username', '');

                const date = new Date();
                let zone: number = date.getTimezoneOffset() * -1;
                formData.append('timezone', zone.toString());

                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http
                    .post(
                        self.constnatsService.API_CORE_URL +
                            '/api/cleandb/create_csv/',
                        formData,
                        options
                    )
                    .pipe(map((res) => res.json()))
                    .pipe(timeout(2000));
            })
        );

        return chainedObservable$;
    }

    deleteCharts(force_delete: boolean): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken().pipe(
            mergeMap(function (token_response) {
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (
                    !self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES
                ) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                let url = self.constnatsService.API_CORE_URL + '/api/cleandb/clean_charts?force_delete=' + force_delete;
                return self.http
                    .delete(
                        url,
                        options
                    )
                    .pipe(map((res) => res.json()));
            })
        );

        return chainedObservable$;
    }
}
