import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConstantsService {

    // Production settings
    public readonly IDENTITY_SERVER_URL: string = 'https://authentication.modosmart.com';
    public readonly API_CORE_URL: string = 'https://core.modosmart.com';
    public readonly STATIC_URL: string = this.API_CORE_URL + '/wwwroot';
    // public readonly IDENTITY_SERVER_URL: string = 'http://localhost:8000';
    // public readonly API_CORE_URL: string = 'http://localhost:8001';
    // public readonly STATIC_URL: string = this.API_CORE_URL;
    public readonly REJECT_UNAUTHORIZED_SSL_CERTIFICATES: boolean = true;
    // Client
    public readonly CLIENT_ID: string = 'modosmart';
    public readonly CLIENT_SECRET: string = 'modosmart';
    public readonly GRANT_TYPE_CLIENT_CREDENTIALS: string = 'client_credentials';
    public readonly REQUESTED_SCOPE: string = 'modosmart-api';
    public readonly GRANT_TYPE_USER_PASSWORD: string = 'password';
    public readonly GRANT_TYPE_REFRESH_TOKEN: string = 'refresh_token';
    public readonly THRESHOLD_REFRESH_TOKEN_MS: number = 60000;
    // Roles
    public readonly ROLE_DEVELOPER: string = 'developer';
    public readonly ROLE_SUPER_ADMIN: string = 'super';
    public readonly ROLE_ADMIN: string = 'admin';
    public readonly ROLE_USER: string = 'user';
    // Profiles
    public readonly PROFILE_CURRENT: string = 'current';
    public readonly PROFILE_BASIC: string = 'basic';
    public readonly PROFILE_PROFESSIONAL: string = 'professional';
    public readonly PROFILE_ADVANCED: string = 'advanced';
    // Account
    public readonly SUPER_ACCOUNT: string = 'super';
    public readonly CLIENT_ACCOUNT: string = 'client';
    // Status
    public readonly ACTIVE_STATUS: string = 'active';
    public readonly SUSPENDED_STATUS: string = 'suspended';
    // Channels
    public readonly NOTIFICATIONS_CHANNEL_LOG: string = 'log';
    public readonly NOTIFICATIONS_CHANNEL_REPORT: string = 'report';
    public readonly NOTIFICATIONS_CHANNEL_NEWS: string = 'news';
    public readonly NOTIFICATIONS_CHANNEL_BROADCAST: string = 'broadcast';
    // Levels
    public readonly NOTIFICATIONS_LEVEL_CRITICAL: string = 'critical';
    public readonly NOTIFICATIONS_LEVEL_DEBUG: string = 'debug';
    public readonly NOTIFICATIONS_LEVEL_ERROR: string = 'error';
    public readonly NOTIFICATIONS_LEVEL_INFORMATION: string = 'information';
    public readonly NOTIFICATIONS_LEVEL_NONE: string = 'none';
    public readonly NOTIFICATIONS_LEVEL_TRACE: string = 'trace';
    public readonly NOTIFICATIONS_LEVEL_WARNING: string = 'warning';
    // Tags
    public readonly NOTIFICATIONS_TAG_DEVELOPER: string = 'developer';
    public readonly NOTIFICATIONS_TAG_SUPER: string = 'super';
    public readonly NOTIFICATIONS_TAG_ADMIN: string = 'admin';
    public readonly NOTIFICATIONS_TAG_USER: string = 'user';
    // Gateway States
    public readonly GATEWAY_STATE_ONLINE: string = 'online';
    public readonly GATEWAY_STATE_OFFLINE: string = 'offline';
    public readonly GATEWAY_PORT_STATE_CONNECTEDs: string = 'connected';
    public readonly GATEWAY_PORT_STATE_DISCONNECTED: string = 'disconnected';
    // AC modes
    public readonly AC_MODE_OFF: string = 'off';
    public readonly AC_MODE_COOL: string = 'cool';
    public readonly AC_MODE_HEAT: string = 'heat';
    public readonly AC_MODE_FAN: string = 'fan';
    public readonly AC_MODE_DRY: string = 'dry';
    // Control modes
    public readonly AC_CONTROL_SMART: string = 'smart';
    public readonly AC_CONTROL_MANUAL: string = 'manual';
    // Datawrapper token
    public readonly DATAWRAPPER_TOKEN: string = 'wkxyYP1FRddUR7QzG9oSQfhuHMIIQjZO9BYn8ju8MV5W5ailbOl2j3e2igIJLwu0';
    public readonly DATAWRAPPER_URL: string = 'https://api.datawrapper.de/v3';
    public readonly DATAWRAPPER_LINE_CHART_TYPE: string = 'd3-lines';

    constructor() { }
}
