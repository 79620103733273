import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class ApartmentService {

    constructor(private http: Http, private tokenService: TokenService,
        private constnatsService: ConstantsService) { }

    postApartment(apartment: any, file: File): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                if (file) {
                    formData.append('file', file, file.name);
                }
                formData.append('name', apartment.name);
                formData.append('info', apartment.info);
                if (apartment.client) {
                    formData.append('client', apartment.client);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/apartment/', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    putApartment(apartment: any, file: File): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                if (file) {
                    formData.append('file', file, file.name);
                }
                formData.append('id', apartment.id);
                formData.append('name', apartment.name);
                formData.append('info', apartment.info);
                if (apartment.client) {
                    formData.append('client', apartment.client);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.put(self.constnatsService.API_CORE_URL + '/api/apartment/' + apartment.id, formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getAllApartments(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/apartment/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteApartment(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/apartment/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteApartments(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData
                });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/apartment/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getApartment(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/apartment/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getApartmentByClient(client: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/apartment/client/' + client,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getProperties(client: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                
                let client_query = '';
                if (client)
                {
                    client_query = '?client_name=' + client;
                }
                
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/apartment/properties' + client_query,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }
}
