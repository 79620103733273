import { Component, OnInit } from '@angular/core';
import { RoomService } from '../../services/room/room.service';
import { ReportService } from '../../services/report/report.service';
import { UserService } from '../../services/user/user.service';
import { ConstantsService } from '../../services/utils/constants.service';
import { DeviceService } from '../../services/device/device.service';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClientService } from '../../services/client/client.service';
import * as moment from 'moment';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-readings',
    templateUrl: './readings.component.html',
    styleUrls: ['./readings.component.css'],
})
export class ReadingsComponent implements OnInit {
    selected_table: string = 'nav_item_room_sensor';
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public allDevices: any[] = [];
    public allRooms: any[] = [];
    private roomSensorDatatable: any;
    private mSCDatatable: any;
    // private aCSwitchDatatable: any;
    private mSCBoilerDatatable: any;
    private mSCAirDuctDatatable: any;
    // private mSCFanCoilDatatable: any;
    // private mSCIRBoilerDatatable: any;
    public allRoomsNames: any[] = [];
    public allApartmentsNames: any[] = [];
    public allRoomSensorLogs: any[] = [];
    public allMSCLogs: any[] = [];
    // public allacSwitchLogs: any[] = [];
    public allMSCBoilerLogs: any[] = [];
    public allMSCAirDuctLogs: any[] = [];
    // public allMSCFanCoilLogs: any[] = [];
    // public allMSCIrBoilerLogs: any[] = [];
    public selectedRoomSensorLogs: any[] = [];
    public currentSelectedRoomSensorLog: any = {};
    public selectedMscLogs: any[] = [];
    public currentSelectedMscLog: any = {};
    public selectedAcSwitchLogs: any[] = [];
    public currentSelectedAcSwitchLog: any = {};
    public selectedMscAirDuctLogs: any[] = [];
    public currentSelectedMscAirDuctLog: any = {};
    public selectedMscBoilerLogs: any[] = [];
    public currentSelectedMscBoilerLog: any = {};
    public selectedMscFanCoilLogs: any[] = [];
    public currentSelectedMscFanCoilLog: any = {};
    public selectedMscIrBoilerLogs: any[] = [];
    public currentSelectedMscIrBoilerLog: any = {};
    public toggle_chart: boolean = false;
    public room_sensor_chart_url: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    public msc_chart_url_env: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    public msc_chart_url_ac: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    public allClients: any[] = [];
    public allClientsNames: string[] = [];

    constructor(
        private roomService: RoomService,
        private reportService: ReportService,
        private userService: UserService,
        private constantsService: ConstantsService,
        private deviceService: DeviceService,
        private clientService: ClientService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        let self = this;
        // basic
        $('#m_select2_1').select2({
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_1').on('select2:select', function (e) {
            // Do something
            var apartment_name = e.params.data.text;
            self.populateAllRooms(apartment_name);
        });

        $('#m_select2_2').select2({
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_2').on('select2:select', function (e) {
            // Do something
            var room_name = e.params.data.text;
            console.log('selected room name = ' + room_name);
        });

        $('#m_select2_3').select2({
            allowClear: false,
            width: '100%',
            minimumResultsForSearch: Infinity,
        });

        $('#m_select2_4').select2({
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_4').on('select2:select', function (e) {
            // Do something
            var client_name = e.params.data.text;
            console.log('selected client name = ' + client_name);
            self.populateAllApartments();
        });

        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                    this.getAllClients();
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                    this.getAllClients();
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                    this.getAllDevices();
                }

                if (me.role == this.constantsService.ROLE_USER) {
                    this.getAllDevices();
                }
            },
            (err) => {
                // if reached here, it means the response is error
            }
        );
    }

    getAllRooms(): void {
        this.roomService.getAllRooms().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allRooms = response.value;
                this.populateAllApartments();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allRooms = [];
                this.allApartmentsNames = [];
                this.populateAllApartments();
            }
        );
    }

    getAllDevices(): void {
        this.deviceService.getAllDevices().subscribe(
            (response) => {
                this.allDevices = response.value;
                this.getAllRooms();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allDevices = [];
                this.getAllRooms();
            }
        );
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                this.populateAlllClients();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                this.populateAlllClients();
                console.log('clients empty');
            }
        );
    }

    populateAllRooms(apartment_name: string): void {
        this.allRoomsNames = [];
        $('#m_select2_2').val(null).trigger('change');
        $('#m_select2_2').empty();

        let selected_client = '';

        if ($('#m_select2_4').select2('data').length > 0) {
            selected_client = $('#m_select2_4').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let room_name = this.allRooms[i].name;
            let current_apartment_name = this.allRooms[i].apartmentName;
            if (this.isDeveloper || this.isSuper) {
                if (this.allRooms[i].client == selected_client && current_apartment_name == apartment_name
                    && this.allRoomsNames.indexOf(room_name) == -1) {
                    this.allRoomsNames.push(room_name);
                }
            } else {
                if (current_apartment_name == apartment_name && this.allRoomsNames.indexOf(room_name) == -1) {
                    this.allRoomsNames.push(room_name);
                }
            }
        }

        let self = this;
        $('#m_select2_2').select2({
            data: self.allRoomsNames,
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });
    }

    populateAllApartments(): void {
        this.allApartmentsNames = [];
        $('#m_select2_1').val(null).trigger('change');
        $('#m_select2_1').empty();

        let selected_client= '';

        if  ($('#m_select2_4').select2('data').length > 0) {
            selected_client = $('#m_select2_4').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let apartment_name = this.allRooms[i].apartmentName;
            if (this.isDeveloper || this.isSuper) {
                // we have to check first if room belongs to selected client
                if (this.allRooms[i].client == selected_client && this.allApartmentsNames.indexOf(apartment_name) == -1) {
                    this.allApartmentsNames.push(apartment_name);
                }
            } else {
                if (this.allApartmentsNames.indexOf(apartment_name) == -1) {
                    this.allApartmentsNames.push(apartment_name);
                }
            }
        }

        let self = this;
        $('#m_select2_1').select2({
            data: self.allApartmentsNames,
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        if ($('#m_select2_1').select2('data').length > 0) {
            let selected_apartment = $('#m_select2_1').select2('data')[0].text;
            if (selected_apartment) {
                this.populateAllRooms(selected_apartment);
            }
        } else {
            this.populateAllRooms('');
        }
    }

    populateAlllClients(): void {
        this.allClientsNames = [];
        $('#m_select2_4').val(null).trigger('change');
        $('#m_select2_4').empty();

        for (let i = 0; i < this.allClients.length; i++) {
            let client_name = this.allClients[i].clientName;
            if (this.allClientsNames.indexOf(client_name) == -1) {
                this.allClientsNames.push(client_name);
            }
        }

        let self = this;
        $('#m_select2_4').select2({
            data: self.allClientsNames,
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        this.getAllDevices();
    }

    public changeActive(myId: string): void {
        // Remove any active
        $('a.active').toggleClass('active');
        // Add active to the required ID
        let id = '#' + myId;
        $(id).toggleClass('active');
        this.selected_table = myId;
    }

    public report() {
        this.allRoomSensorLogs = [];
        this.allMSCLogs  = [];
        this.allMSCAirDuctLogs = [];
        this.allMSCBoilerLogs = [];
        // this.allMSCFanCoilLogs = [];
        // this.allMSCIrBoilerLogs = [];
        // this.allacSwitchLogs = [];
        this.getRoomSensorLog();
        this.getMSCLog();
        this.getMscAirDuctLog();
        this.getMscBoilerLog();
        // this.getMscFanCoilLog();
        // this.getMscIrBoilerLog();
        // this.getAcSwitchLog();
    }

    private getRoomSensorLog() {
        // Get the apartment name
        let selected_apartment = $('#m_select2_1').select2('data')[0].text;
        // Get the room name
        let selected_room = $('#m_select2_2').select2('data')[0].text;
        // Get the period required
        let selected_period = $('#m_select2_3').select2('data')[0].id;
        // Get the period required
        var selected_client = null;
        if (this.isDeveloper || this.isSuper)
        {
            if ($('#m_select2_4').select2('data').length > 0)
            {
                selected_client = $('#m_select2_4').select2('data')[0].id;
            }
        }

        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...',
        });

        this.reportService
            .getAllRoomSensorsLog(
                selected_apartment,
                selected_room,
                selected_period,
                selected_client
            )
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.allRoomSensorLogs = response.value.logs;
                    mApp.unblock('#m_table_1');
                    if (this.roomSensorDatatable) {
                        this.roomSensorDatatable.clear();
                        this.roomSensorDatatable.destroy();
                        // this.roomSensorDatatable.draw();
                    }
                    this.InitRoomSensorTable();
                    this.room_sensor_chart_url = this.sanitizer.bypassSecurityTrustResourceUrl(response.value.chart_url);
                },
                (err) => {
                    // if reached here, it means the response is error
                    this.allRoomSensorLogs = [];
                    mApp.unblock('#m_table_1');
                    if (this.roomSensorDatatable) {
                        this.roomSensorDatatable.clear();
                        this.roomSensorDatatable.destroy();
                        // this.roomSensorDatatable.draw();
                    }
                    this.InitRoomSensorTable();
                    this.room_sensor_chart_url = this.sanitizer.bypassSecurityTrustResourceUrl("");
                }
            );
    }

    private getMSCLog() {
        // Get the apartment name
        let selected_apartment = $('#m_select2_1').select2('data')[0].text;
        // Get the room name
        let selected_room = $('#m_select2_2').select2('data')[0].text;
        // Get the period required
        let selected_period = $('#m_select2_3').select2('data')[0].id;
        // Get the period required
        var selected_client = null;
        if (this.isDeveloper || this.isSuper)
        {
            if ($('#m_select2_4').select2('data').length > 0)
            {
                selected_client = $('#m_select2_4').select2('data')[0].id;
            }
        }

        mApp.block('#m_table_2', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...',
        });

        this.reportService
            .getAllMSCLog(selected_apartment, selected_room, selected_period, selected_client)
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.allMSCLogs = response.value.logs;
                    mApp.unblock('#m_table_2');
                    if (this.mSCDatatable) {
                        this.mSCDatatable.clear();
                        this.mSCDatatable.destroy();
                        // this.mSCDatatable.draw();
                    }
                    this.InitMscTable();
                    this.msc_chart_url_env = this.sanitizer.bypassSecurityTrustResourceUrl(response.value.chart_url_env);
                    this.msc_chart_url_ac = this.sanitizer.bypassSecurityTrustResourceUrl(response.value.chart_url_ac);
                },
                (err) => {
                    // if reached here, it means the response is error
                    this.allMSCLogs = [];
                    mApp.unblock('#m_table_2');
                    if (this.mSCDatatable) {
                        this.mSCDatatable.clear();
                        this.mSCDatatable.destroy();
                        // this.mSCDatatable.draw();
                    }
                    this.InitMscTable();
                    this.msc_chart_url_env = this.sanitizer.bypassSecurityTrustResourceUrl("");
                    this.msc_chart_url_ac = this.sanitizer.bypassSecurityTrustResourceUrl("");
                }
            );
    }

    private getMscBoilerLog() {
        // Get the apartment name
        let selected_apartment = $('#m_select2_1').select2('data')[0].text;
        // Get the room name
        let selected_room = $('#m_select2_2').select2('data')[0].text;
        // Get the period required
        let selected_period = $('#m_select2_3').select2('data')[0].id;
        // Get the period required
        var selected_client = null;
        if (this.isDeveloper || this.isSuper)
        {
            if ($('#m_select2_4').select2('data').length > 0)
            {
                selected_client = $('#m_select2_4').select2('data')[0].id;
            }
        }

        mApp.block('#m_table_3', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...',
        });

        this.reportService
            .getAllMSCBoilerLog(selected_apartment, selected_room, selected_period, selected_client)
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.allMSCBoilerLogs = response.value;
                    mApp.unblock('#m_table_3');
                    if (this.mSCBoilerDatatable) {
                        this.mSCBoilerDatatable.clear();
                        this.mSCBoilerDatatable.destroy();
                        // this.mSCBoilerDatatable.draw();
                    }
                    this.InitMscBoilerTable();
                },
                (err) => {
                    // if reached here, it means the response is error
                    this.allMSCBoilerLogs = [];
                    mApp.unblock('#m_table_3');
                    if (this.mSCBoilerDatatable) {
                        this.mSCBoilerDatatable.clear();
                        this.mSCBoilerDatatable.destroy();
                        // this.mSCBoilerDatatable.draw();
                    }
                    this.InitMscBoilerTable();
                }
            );
    }

    private getMscAirDuctLog() {
        // Get the apartment name
        let selected_apartment = $('#m_select2_1').select2('data')[0].text;
        // Get the room name
        let selected_room = $('#m_select2_2').select2('data')[0].text;
        // Get the period required
        let selected_period = $('#m_select2_3').select2('data')[0].id;
        // Get the period required
        var selected_client = null;
        if (this.isDeveloper || this.isSuper)
        {
            if ($('#m_select2_4').select2('data').length > 0)
            {
                selected_client = $('#m_select2_4').select2('data')[0].id;
            }
        }

        mApp.block('#m_table_4', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...',
        });

        this.reportService
            .getAllMSCAirDuctLog(selected_apartment, selected_room, selected_period, selected_client)
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.allMSCAirDuctLogs = response.value;
                    mApp.unblock('#m_table_4');
                    if (this.mSCAirDuctDatatable) {
                        this.mSCAirDuctDatatable.clear();
                        this.mSCAirDuctDatatable.destroy();
                        // this.mSCAirDuctDatatable.draw();
                    }
                    this.InitMscAirDuctTable();
                },
                (err) => {
                    // if reached here, it means the response is error
                    this.allMSCAirDuctLogs = [];
                    mApp.unblock('#m_table_4');
                    if (this.mSCAirDuctDatatable) {
                        this.mSCAirDuctDatatable.clear();
                        this.mSCAirDuctDatatable.destroy();
                        // this.mSCAirDuctDatatable.draw();
                    }
                    this.InitMscAirDuctTable();
                }
            );
    }

    // private getMscFanCoilLog() {
    //     // Get the apartment name
    //     let selected_apartment = $('#m_select2_1').select2('data')[0].text;
    //     // Get the room name
    //     let selected_room = $('#m_select2_2').select2('data')[0].text;
    //     // Get the period required
    //     let selected_period = $('#m_select2_3').select2('data')[0].id;
    //     // Get the period required
    //     var selected_client = null;
    //     if (this.isDeveloper || this.isSuper)
    //     {
    //         selected_client = $('#m_select2_4').select2('data')[0].id;
    //     }

    //     mApp.block('#m_table_5', {
    //         overlayColor: '#000000',
    //         type: 'loader',
    //         state: 'success',
    //         message: 'Please wait...',
    //     });

    //     this.reportService
    //         .getAllMSCFanCoilLog(selected_apartment, selected_room, selected_period, selected_client)
    //         .subscribe(
    //             (response) => {
    //                 // if reached here, it means the response is success
    //                 this.allMSCFanCoilLogs = response.value;
    //                 mApp.unblock('#m_table_5');
    //                 if (this.mSCFanCoilDatatable) {
    //                     this.mSCFanCoilDatatable.clear();
    //                     this.mSCFanCoilDatatable.destroy();
    //                     // this.mSCFanCoilDatatable.draw();
    //                 }
    //                 this.InitMscFanCoilTable();
    //             },
    //             (err) => {
    //                 // if reached here, it means the response is error
    //                 this.allMSCFanCoilLogs = [];
    //                 mApp.unblock('#m_table_5');
    //                 if (this.mSCFanCoilDatatable) {
    //                     this.mSCFanCoilDatatable.clear();
    //                     this.mSCFanCoilDatatable.destroy();
    //                     // this.mSCFanCoilDatatable.draw();
    //                 }
    //                 this.InitMscFanCoilTable();
    //             }
    //         );
    // }

    // private getMscIrBoilerLog() {
    //     // Get the apartment name
    //     let selected_apartment = $('#m_select2_1').select2('data')[0].text;
    //     // Get the room name
    //     let selected_room = $('#m_select2_2').select2('data')[0].text;
    //     // Get the period required
    //     let selected_period = $('#m_select2_3').select2('data')[0].id;
    //     // Get the period required
    //     var selected_client = null;
    //     if (this.isDeveloper || this.isSuper)
    //     {
    //         selected_client = $('#m_select2_4').select2('data')[0].id;
    //     }

    //     mApp.block('#m_table_6', {
    //         overlayColor: '#000000',
    //         type: 'loader',
    //         state: 'success',
    //         message: 'Please wait...',
    //     });

    //     this.reportService
    //         .getAllMSCIRBoilerLog(selected_apartment, selected_room, selected_period, selected_client)
    //         .subscribe(
    //             (response) => {
    //                 // if reached here, it means the response is success
    //                 this.allMSCIrBoilerLogs = response.value;
    //                 mApp.unblock('#m_table_6');
    //                 if (this.mSCIRBoilerDatatable) {
    //                     this.mSCIRBoilerDatatable.clear();
    //                     this.mSCIRBoilerDatatable.destroy();
    //                     // this.mSCIRBoilerDatatable.draw();
    //                 }
    //                 this.InitMscIrBoilerTable();
    //             },
    //             (err) => {
    //                 // if reached here, it means the response is error
    //                 this.allMSCIrBoilerLogs = [];
    //                 mApp.unblock('#m_table_6');
    //                 if (this.mSCIRBoilerDatatable) {
    //                     this.mSCIRBoilerDatatable.clear();
    //                     this.mSCIRBoilerDatatable.destroy();
    //                     // this.mSCIRBoilerDatatable.draw();
    //                 }
    //                 this.InitMscIrBoilerTable();
    //             }
    //         );
    // }

    // private getAcSwitchLog() {
    //     // Get the apartment name
    //     let selected_apartment = $('#m_select2_1').select2('data')[0].text;
    //     // Get the room name
    //     let selected_room = $('#m_select2_2').select2('data')[0].text;
    //     // Get the period required
    //     let selected_period = $('#m_select2_3').select2('data')[0].id;
    //     // Get the period required
    //     var selected_client = null;
    //     if (this.isDeveloper || this.isSuper)
    //     {
    //         selected_client = $('#m_select2_4').select2('data')[0].id;
    //     }

    //     mApp.block('#m_table_7', {
    //         overlayColor: '#000000',
    //         type: 'loader',
    //         state: 'success',
    //         message: 'Please wait...',
    //     });

    //     this.reportService
    //         .getAllAcSwitchLog(selected_apartment, selected_room, selected_period, selected_client)
    //         .subscribe(
    //             (response) => {
    //                 // if reached here, it means the response is success
    //                 this.allacSwitchLogs = response.value;
    //                 mApp.unblock('#m_table_7');
    //                 if (this.aCSwitchDatatable) {
    //                     this.aCSwitchDatatable.clear();
    //                     this.aCSwitchDatatable.destroy();
    //                     // this.aCSwitchDatatable.draw();
    //                 }
    //                 this.InitAcSwitchTable();
    //             },
    //             (err) => {
    //                 // if reached here, it means the response is error
    //                 this.allacSwitchLogs = [];
    //                 mApp.unblock('#m_table_7');
    //                 if (this.aCSwitchDatatable) {
    //                     this.aCSwitchDatatable.clear();
    //                     this.aCSwitchDatatable.destroy();
    //                     // this.aCSwitchDatatable.draw();
    //                 }
    //                 this.InitAcSwitchTable();
    //             }
    //         );
    // }

    private get_apartment(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].apartment;
            }
        }
        return '';
    }

    private get_room(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].room;
            }
        }
        return '';
    }

    private get_gateway(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].gateway;
            }
        }
        return '';
    }

    private get_name(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].name;
            }
        }
        return '';
    }

    InitRoomSensorTable(): void {
        let self = this;
        // begin first table
        this.roomSensorDatatable = $('#m_table_1').DataTable({
            data: this.allRoomSensorLogs,
            responsive: true,
            // paging: true,
            //== DOM Layout settings
            dom:
                "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                lengthMenu: 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                      <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                          <input type="checkbox" value="" class="m-group-checkable">
                          <span></span>
                      </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: 'id',
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <label id="` +
                            full.id +
                            `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                              <input type="checkbox" value="" class="m-checkable">
                              <span></span>
                          </label>`
                        );
                    },
                },
                {
                    targets: 1,
                    data: 'mac_address',
                    title: 'Mac Address',
                    className: 'text-center',
                },
                {
                    targets: 2,
                    data: 'mac_address',
                    title: 'Name',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_name(data) + '</div>';
                    },
                },
                {
                    targets: 3,
                    data: 'timestamp',
                    title: 'timestamp',
                    render: function (data, type, full, meta) {
                        // let dateISOString = data;
                        // let date = new Date(dateISOString);
                        // date = new Date(
                        //     date.getTime() + date.getTimezoneOffset() * 60000
                        // );
                        // // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        // let options = {};                        
                        // return (
                        //     '<span>' +
                        //     date.toLocaleString([], options) +
                        //     '</span>'
                        // );

                        // Convert the string to a moment object
                        const localTime = moment(data).local().format('YYYY-MM-DD HH:mm:ss');
                        return (
                            '<span>' +
                            localTime +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 4,
                    data: 'mac_address',
                    title: 'Gateway',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_gateway(data) + '</div>';
                    },
                },
                {
                    targets: 5,
                    data: 'temperature',
                    title: 'Temperature',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '°C</div>';
                    },
                },
                {
                    targets: 6,
                    data: 'humidity',
                    title: 'Humidity',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 7,
                    data: 'presence',
                    title: 'Presence',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        var status = {
                            0: { title: 'Present', class: 'm-badge--success' },
                            1: { title: 'Absent', class: ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = data ? 0 : 1;
                        return (
                            '<span class="m-badge ' +
                            status[location].class +
                            ' m-badge--wide">' +
                            status[location].title +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 8,
                    data: 'presence_val',
                    title: 'Presence Val',
                    className: 'text-center',
                },
                {
                    targets: 9,
                    data: 'batteryLevel',
                    title: 'Battery',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 10,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <a href="javascript:void(0);" id="` +
                            full.id +
                            `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                            <i class="la la-trash"></i>
                          </a>`
                        );
                    },
                },
            ],
        });

        this.roomSensorDatatable.on(
            'change',
            '.m-group-checkable',
            function () {
                var set = $(this)
                    .closest('table')
                    .find('td:first-child .m-checkable');
                var checked = $(this).is(':checked');

                $(set).each(function () {
                    if (checked) {
                        $(this).prop('checked', true);
                        $(this).closest('tr').addClass('active');
                    } else {
                        $(this).prop('checked', false);
                        $(this).closest('tr').removeClass('active');
                    }
                });

                if (checked) {
                    for (let i = 0; i < self.allRoomSensorLogs.length; i++) {
                        self.selectedRoomSensorLogs[i] =
                            self.allRoomSensorLogs[i].id;
                    }
                } else {
                    self.selectedRoomSensorLogs = [];
                }
            }
        );

        this.roomSensorDatatable.on(
            'change',
            'tbody tr .m-checkbox',
            function () {
                let id = $(this).attr('id');

                let isChecked = $(this)
                    .parents('tr')
                    .find('td:first-child .m-checkable')
                    .is(':checked');

                if (isChecked) {
                    //return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedRoomSensorLogs);
                    if (found < 0) {
                        // Element was not found, add it.
                        self.selectedRoomSensorLogs.push(id);
                    }
                } else {
                    // return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedRoomSensorLogs);
                    if (found >= 0) {
                        // Element was found, remove it.
                        self.selectedRoomSensorLogs.splice(found, 1);
                    }
                }
            }
        );

        $('#m_table_1').on('click', 'a.m-portlet__nav-link', function () {
            let id = $(this).attr('id');
            self.DeleteRoomSensorLog(id);
        });

        // this.roomSensorDatatable.on(
        //     'init.dt',
        //     function () {
        //         console.log('datatable init')
        //     }
        // );
    }
    
    InitMscTable(): void {
        let self = this;
        // begin first table
        this.mSCDatatable = $('#m_table_2').DataTable({
            data: this.allMSCLogs,
            responsive: true,
            // paging: true,
            //== DOM Layout settings
            dom:
                "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                lengthMenu: 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                      <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                          <input type="checkbox" value="" class="m-group-checkable">
                          <span></span>
                      </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: 'id',
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <label id="` +
                            full.id +
                            `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                              <input type="checkbox" value="" class="m-checkable">
                              <span></span>
                          </label>`
                        );
                    },
                },
                {
                    targets: 1,
                    data: 'mac_address',
                    title: 'Mac Address',
                    className: 'text-center',
                },
                {
                    targets: 2,
                    data: 'mac_address',
                    title: 'Name',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_name(data) + '</div>';
                    },
                },
                {
                    targets: 3,
                    data: 'timestamp',
                    title: 'timestamp',
                    render: function (data, type, full, meta) {
                        // let dateISOString = data;
                        // let date = new Date(dateISOString);
                        // date = new Date(
                        //     date.getTime() + date.getTimezoneOffset() * 60000
                        // );
                        // // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        // let options = {};
                        // return (
                        //     '<span>' +
                        //     date.toLocaleString([], options) +
                        //     '</span>'
                        // );

                        // Convert the string to a moment object
                        const localTime = moment(data).local().format('YYYY-MM-DD HH:mm:ss');
                        return (
                            '<span>' +
                            localTime +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 4,
                    data: 'mac_address',
                    title: 'Gateway',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_gateway(data) + '</div>';
                    },
                },
                {
                    targets: 5,
                    data: 'temperature',
                    title: 'Temperature',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '°C</div>';
                    },
                },
                {
                    targets: 6,
                    data: 'humidity',
                    title: 'Humidity',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 7,
                    data: 'presence',
                    title: 'Presence',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        var status = {
                            0: { title: 'Present', class: 'm-badge--success' },
                            1: { title: 'Absent', class: ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = data ? 0 : 1;
                        return (
                            '<span class="m-badge ' +
                            status[location].class +
                            ' m-badge--wide">' +
                            status[location].title +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 8,
                    data: 'presence_val',
                    title: 'Presence Val',
                    className: 'text-center',
                },
                {
                    targets: 9,
                    data: 'batteryLevel',
                    title: 'Battery',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 10,
                    data: 'ir_library',
                    title: 'IR Library',
                    className: 'text-center',
                },
                {
                    targets: 11,
                    data: 'ac_mode',
                    title: 'Mode',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'OFF', 'class': 'm-badge--meatl' },
                            1: { 'title': 'Cool', 'class': ' m-badge--primary' },
                            2: { 'title': 'Heat', 'class': ' m-badge--danger' },
                            3: { 'title': 'Fan', 'class': ' m-badge--success' },
                            4: { 'title': 'Dry', 'class': ' m-badge--warning' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == self.constantsService.AC_MODE_OFF) {
                            location = 0;
                        } else if (data == self.constantsService.AC_MODE_COOL) {
                            location = 1;
                        } else if (data == self.constantsService.AC_MODE_HEAT) {
                            location = 2;
                        } else if (data == self.constantsService.AC_MODE_FAN) {
                            location = 3;
                        } else {
                            location = 4;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 12,
                    data: 'ac_temperature',
                    title: 'AC Temp',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '°C</div>';
                    },
                },
                {
                    targets: 13,
                    data: 'enabled',
                    title: 'Eanbled',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Enabled', 'class': 'm-badge--success' },
                            1: { 'title': 'Disabled', 'class': ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 14,
                    data: 'ac_control',
                    title: 'Control',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'smart', 'class': ' m-badge--success' },
                            1: { 'title': 'manual', 'class': 'm-badge--primary' }
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == self.constantsService.AC_CONTROL_SMART) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 15,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <a href="javascript:void(0);" id="` +
                            full.id +
                            `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                            <i class="la la-trash"></i>
                          </a>`
                        );
                    },
                },
            ],
        });

        this.mSCDatatable.on(
            'change',
            '.m-group-checkable',
            function () {
                var set = $(this)
                    .closest('table')
                    .find('td:first-child .m-checkable');
                var checked = $(this).is(':checked');

                $(set).each(function () {
                    if (checked) {
                        $(this).prop('checked', true);
                        $(this).closest('tr').addClass('active');
                    } else {
                        $(this).prop('checked', false);
                        $(this).closest('tr').removeClass('active');
                    }
                });

                if (checked) {
                    for (let i = 0; i < self.allMSCLogs.length; i++) {
                        self.selectedMscLogs[i] =
                            self.allMSCLogs[i].id;
                    }
                } else {
                    self.selectedMscLogs = [];
                }
            }
        );

        this.mSCDatatable.on(
            'change',
            'tbody tr .m-checkbox',
            function () {
                let id = $(this).attr('id');

                let isChecked = $(this)
                    .parents('tr')
                    .find('td:first-child .m-checkable')
                    .is(':checked');

                if (isChecked) {
                    //return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscLogs);
                    if (found < 0) {
                        // Element was not found, add it.
                        self.selectedMscLogs.push(id);
                    }
                } else {
                    // return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscLogs);
                    if (found >= 0) {
                        // Element was found, remove it.
                        self.selectedMscLogs.splice(found, 1);
                    }
                }
            }
        );

        $('#m_table_2').on('click', 'a.m-portlet__nav-link', function () {
            let id = $(this).attr('id');
            self.DeleteMSCLog(id);
        });

        // this.mSCDatatable.on(
        //     'init.dt',
        //     function () {
        //         console.log('datatable init')
        //     }
        // );
    }

    InitMscBoilerTable(): void {
        let self = this;
        // begin first table
        this.mSCBoilerDatatable = $('#m_table_3').DataTable({
            data: this.allMSCBoilerLogs,
            responsive: true,
            // paging: true,
            //== DOM Layout settings
            dom:
                "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                lengthMenu: 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                      <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                          <input type="checkbox" value="" class="m-group-checkable">
                          <span></span>
                      </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: 'id',
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <label id="` +
                            full.id +
                            `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                              <input type="checkbox" value="" class="m-checkable">
                              <span></span>
                          </label>`
                        );
                    },
                },
                {
                    targets: 1,
                    data: 'mac_address',
                    title: 'Mac Address',
                    className: 'text-center',
                },
                {
                    targets: 2,
                    data: 'mac_address',
                    title: 'Name',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_name(data) + '</div>';
                    },
                },
                {
                    targets: 3,
                    data: 'timestamp',
                    title: 'timestamp',
                    render: function (data, type, full, meta) {
                        // let dateISOString = data;
                        // let date = new Date(dateISOString);
                        // date = new Date(
                        //     date.getTime() + date.getTimezoneOffset() * 60000
                        // );
                        // // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        // let options = {};
                        // return (
                        //     '<span>' +
                        //     date.toLocaleString([], options) +
                        //     '</span>'
                        // );

                        // Convert the string to a moment object
                        const localTime = moment(data).local().format('YYYY-MM-DD HH:mm:ss');
                        return (
                            '<span>' +
                            localTime +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 4,
                    data: 'mac_address',
                    title: 'Gateway',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_gateway(data) + '</div>';
                    },
                },
                {
                    targets: 5,
                    data: 'temperature',
                    title: 'Temperature',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '°C</div>';
                    },
                },
                {
                    targets: 6,
                    data: 'humidity',
                    title: 'Humidity',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 7,
                    data: 'presence',
                    title: 'Presence',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        var status = {
                            0: { title: 'Present', class: 'm-badge--success' },
                            1: { title: 'Absent', class: ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = data ? 0 : 1;
                        return (
                            '<span class="m-badge ' +
                            status[location].class +
                            ' m-badge--wide">' +
                            status[location].title +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 8,
                    data: 'batteryLevel',
                    title: 'Battery',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 9,
                    data: 'boiler_state',
                    title: 'State',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'OFF', 'class': 'm-badge--danger' },
                            1: { 'title': 'ON', 'class': ' m-badge--success' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == 0) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 10,
                    data: 'boiler_temperature',
                    title: 'Boiler Temp',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '°C</div>';
                    },
                },
                {
                    targets: 11,
                    data: 'enabled',
                    title: 'Eanbled',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Enabled', 'class': 'm-badge--success' },
                            1: { 'title': 'Disabled', 'class': ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 12,
                    data: 'boiler_control',
                    title: 'Control',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'smart', 'class': ' m-badge--success' },
                            1: { 'title': 'manual', 'class': 'm-badge--primary' }
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == self.constantsService.AC_CONTROL_SMART) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 13,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <a href="javascript:void(0);" id="` +
                            full.id +
                            `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                            <i class="la la-trash"></i>
                          </a>`
                        );
                    },
                },
            ],
        });

        this.mSCBoilerDatatable.on(
            'change',
            '.m-group-checkable',
            function () {
                var set = $(this)
                    .closest('table')
                    .find('td:first-child .m-checkable');
                var checked = $(this).is(':checked');

                $(set).each(function () {
                    if (checked) {
                        $(this).prop('checked', true);
                        $(this).closest('tr').addClass('active');
                    } else {
                        $(this).prop('checked', false);
                        $(this).closest('tr').removeClass('active');
                    }
                });

                if (checked) {
                    for (let i = 0; i < self.allMSCBoilerLogs.length; i++) {
                        self.selectedMscBoilerLogs[i] =
                            self.allMSCBoilerLogs[i].id;
                    }
                } else {
                    self.selectedMscBoilerLogs = [];
                }
            }
        );

        this.mSCBoilerDatatable.on(
            'change',
            'tbody tr .m-checkbox',
            function () {
                let id = $(this).attr('id');

                let isChecked = $(this)
                    .parents('tr')
                    .find('td:first-child .m-checkable')
                    .is(':checked');

                if (isChecked) {
                    //return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscBoilerLogs);
                    if (found < 0) {
                        // Element was not found, add it.
                        self.selectedMscBoilerLogs.push(id);
                    }
                } else {
                    // return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscBoilerLogs);
                    if (found >= 0) {
                        // Element was found, remove it.
                        self.selectedMscBoilerLogs.splice(found, 1);
                    }
                }
            }
        );

        $('#m_table_2').on('click', 'a.m-portlet__nav-link', function () {
            let id = $(this).attr('id');
            self.DeleteMSCBoilerLog(id);
        });

        // this.mSCBoilerDatatable.on(
        //     'init.dt',
        //     function () {
        //         console.log('datatable init')
        //     }
        // );
    }

    InitMscAirDuctTable(): void {
        let self = this;
        // begin first table
        this.mSCAirDuctDatatable = $('#m_table_4').DataTable({
            data: this.allMSCAirDuctLogs,
            responsive: true,
            // paging: true,
            //== DOM Layout settings
            dom:
                "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                lengthMenu: 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                      <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                          <input type="checkbox" value="" class="m-group-checkable">
                          <span></span>
                      </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: 'id',
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <label id="` +
                            full.id +
                            `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                              <input type="checkbox" value="" class="m-checkable">
                              <span></span>
                          </label>`
                        );
                    },
                },
                {
                    targets: 1,
                    data: 'mac_address',
                    title: 'Mac Address',
                    className: 'text-center',
                },
                {
                    targets: 2,
                    data: 'mac_address',
                    title: 'Name',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_name(data) + '</div>';
                    },
                },
                {
                    targets: 3,
                    data: 'timestamp',
                    title: 'timestamp',
                    render: function (data, type, full, meta) {
                        // let dateISOString = data;
                        // let date = new Date(dateISOString);
                        // date = new Date(
                        //     date.getTime() + date.getTimezoneOffset() * 60000
                        // );
                        // // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        // let options = {};
                        // return (
                        //     '<span>' +
                        //     date.toLocaleString([], options) +
                        //     '</span>'
                        // );

                        // Convert the string to a moment object
                        const localTime = moment(data).local().format('YYYY-MM-DD HH:mm:ss');
                        return (
                            '<span>' +
                            localTime +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 4,
                    data: 'mac_address',
                    title: 'Gateway',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_gateway(data) + '</div>';
                    },
                },
                {
                    targets: 5,
                    data: 'temperature',
                    title: 'Temperature',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '°C</div>';
                    },
                },
                {
                    targets: 6,
                    data: 'humidity',
                    title: 'Humidity',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 7,
                    data: 'presence',
                    title: 'Presence',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        var status = {
                            0: { title: 'Present', class: 'm-badge--success' },
                            1: { title: 'Absent', class: ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = data ? 0 : 1;
                        return (
                            '<span class="m-badge ' +
                            status[location].class +
                            ' m-badge--wide">' +
                            status[location].title +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 8,
                    data: 'batteryLevel',
                    title: 'Battery',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '%</div>';
                    },
                },
                {
                    targets: 9,
                    data: 'air_duct_state',
                    title: 'State',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'OFF', 'class': 'm-badge--danger' },
                            1: { 'title': 'ON', 'class': ' m-badge--success' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == 0) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 10,
                    data: 'enabled',
                    title: 'Eanbled',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Enabled', 'class': 'm-badge--success' },
                            1: { 'title': 'Disabled', 'class': ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 11,
                    data: 'air_duct_control',
                    title: 'Control',
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'smart', 'class': ' m-badge--success' },
                            1: { 'title': 'manual', 'class': 'm-badge--primary' }
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == self.constantsService.AC_CONTROL_SMART) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 12,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <a href="javascript:void(0);" id="` +
                            full.id +
                            `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                            <i class="la la-trash"></i>
                          </a>`
                        );
                    },
                },
            ],
        });

        this.mSCAirDuctDatatable.on(
            'change',
            '.m-group-checkable',
            function () {
                var set = $(this)
                    .closest('table')
                    .find('td:first-child .m-checkable');
                var checked = $(this).is(':checked');

                $(set).each(function () {
                    if (checked) {
                        $(this).prop('checked', true);
                        $(this).closest('tr').addClass('active');
                    } else {
                        $(this).prop('checked', false);
                        $(this).closest('tr').removeClass('active');
                    }
                });

                if (checked) {
                    for (let i = 0; i < self.allMSCAirDuctLogs.length; i++) {
                        self.selectedMscAirDuctLogs[i] =
                            self.allMSCAirDuctLogs[i].id;
                    }
                } else {
                    self.selectedMscAirDuctLogs = [];
                }
            }
        );

        this.mSCAirDuctDatatable.on(
            'change',
            'tbody tr .m-checkbox',
            function () {
                let id = $(this).attr('id');

                let isChecked = $(this)
                    .parents('tr')
                    .find('td:first-child .m-checkable')
                    .is(':checked');

                if (isChecked) {
                    //return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscAirDuctLogs);
                    if (found < 0) {
                        // Element was not found, add it.
                        self.selectedMscAirDuctLogs.push(id);
                    }
                } else {
                    // return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscAirDuctLogs);
                    if (found >= 0) {
                        // Element was found, remove it.
                        self.selectedMscAirDuctLogs.splice(found, 1);
                    }
                }
            }
        );

        $('#m_table_4').on('click', 'a.m-portlet__nav-link', function () {
            let id = $(this).attr('id');
            self.DeleteMSCAirDuctLog(id);
        });

        // this.mSCAirDuctDatatable.on(
        //     'init.dt',
        //     function () {
        //         console.log('datatable init')
        //     }
        // );
    }

    // InitMscFanCoilTable(): void {
    //     let self = this;
    //     // begin first table
    //     this.mSCFanCoilDatatable = $('#m_table_5').DataTable({
    //         data: this.allMSCFanCoilLogs,
    //         responsive: true,
    //         // paging: true,
    //         //== DOM Layout settings
    //         dom:
    //             "<'row'<'col-sm-12'f>>" +
    //             "<'row'<'col-sm-12'tr>>" +
    //             "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

    //         pageLength: 10,

    //         language: {
    //             lengthMenu: 'Display _MENU_',
    //         },

    //         //== Order settings
    //         order: [[1, 'desc']],

    //         headerCallback: function (thead, data, start, end, display) {
    //             thead.getElementsByTagName('th')[0].innerHTML = `
    //                   <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
    //                       <input type="checkbox" value="" class="m-group-checkable">
    //                       <span></span>
    //                   </label>`;
    //         },

    //         columnDefs: [
    //             {
    //                 targets: 0,
    //                 data: 'id',
    //                 width: '30px',
    //                 className: 'dt-right',
    //                 orderable: false,
    //                 render: function (data, type, full, meta) {
    //                     return (
    //                         `
    //                       <label id="` +
    //                         full.id +
    //                         `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
    //                           <input type="checkbox" value="" class="m-checkable">
    //                           <span></span>
    //                       </label>`
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 1,
    //                 data: 'mac_address',
    //                 title: 'Mac Address',
    //                 className: 'text-center',
    //             },
    //             {
    //                 targets: 2,
    //                 data: 'mac_address',
    //                 title: 'Name',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + self.get_name(data) + '</div>';
    //                 },
    //             },
    //             {
    //                 targets: 3,
    //                 data: 'timestamp',
    //                 title: 'timestamp',
    //                 render: function (data, type, full, meta) {
    //                     let dateISOString = data;
    //                     let date = new Date(dateISOString);
    //                     date = new Date(
    //                         date.getTime() + date.getTimezoneOffset() * 60000
    //                     );
    //                     // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //                     let options = {};
    //                     return (
    //                         '<span>' +
    //                         date.toLocaleString([], options) +
    //                         '</span>'
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 4,
    //                 data: 'mac_address',
    //                 title: 'Gateway',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + self.get_gateway(data) + '</div>';
    //                 },
    //             },
    //             {
    //                 targets: 5,
    //                 data: 'temperature',
    //                 title: 'Temperature',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '°C</div>';
    //                 },
    //             },
    //             {
    //                 targets: 6,
    //                 data: 'humidity',
    //                 title: 'Humidity',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '%</div>';
    //                 },
    //             },
    //             {
    //                 targets: 7,
    //                 data: 'presence',
    //                 title: 'Presence',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     var status = {
    //                         0: { title: 'Present', class: 'm-badge--success' },
    //                         1: { title: 'Absent', class: ' m-badge--danger' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = data ? 0 : 1;
    //                     return (
    //                         '<span class="m-badge ' +
    //                         status[location].class +
    //                         ' m-badge--wide">' +
    //                         status[location].title +
    //                         '</span>'
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 8,
    //                 data: 'batteryLevel',
    //                 title: 'Battery',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '%</div>';
    //                 },
    //             },
    //             {
    //                 targets: 9,
    //                 data: 'coil_mode',
    //                 title: 'Mode',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'OFF', 'class': 'm-badge--meatl' },
    //                         1: { 'title': 'Cool', 'class': ' m-badge--primary' },
    //                         2: { 'title': 'Heat', 'class': ' m-badge--danger' },
    //                         3: { 'title': 'Fan', 'class': ' m-badge--success' },
    //                         4: { 'title': 'Dry', 'class': ' m-badge--warning' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == self.constantsService.AC_MODE_OFF) {
    //                         location = 0;
    //                     } else if (data == self.constantsService.AC_MODE_COOL) {
    //                         location = 1;
    //                     } else if (data == self.constantsService.AC_MODE_HEAT) {
    //                         location = 2;
    //                     } else if (data == self.constantsService.AC_MODE_FAN) {
    //                         location = 3;
    //                     } else {
    //                         location = 4;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 10,
    //                 data: 'coil_temperature',
    //                 title: 'AC Temp',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '°C</div>';
    //                 },
    //             },
    //             {
    //                 targets: 11,
    //                 data: 'enabled',
    //                 title: 'Eanbled',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'Enabled', 'class': 'm-badge--success' },
    //                         1: { 'title': 'Disabled', 'class': ' m-badge--danger' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 12,
    //                 data: 'coil_control',
    //                 title: 'Control',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'smart', 'class': ' m-badge--success' },
    //                         1: { 'title': 'manual', 'class': 'm-badge--primary' }
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == self.constantsService.AC_CONTROL_SMART) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 13,
    //                 width: '100px',
    //                 title: 'Actions',
    //                 orderable: false,
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return (
    //                         `
    //                       <a href="javascript:void(0);" id="` +
    //                         full.id +
    //                         `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
    //                         <i class="la la-trash"></i>
    //                       </a>`
    //                     );
    //                 },
    //             },
    //         ],
    //     });

    //     this.mSCFanCoilDatatable.on(
    //         'change',
    //         '.m-group-checkable',
    //         function () {
    //             var set = $(this)
    //                 .closest('table')
    //                 .find('td:first-child .m-checkable');
    //             var checked = $(this).is(':checked');

    //             $(set).each(function () {
    //                 if (checked) {
    //                     $(this).prop('checked', true);
    //                     $(this).closest('tr').addClass('active');
    //                 } else {
    //                     $(this).prop('checked', false);
    //                     $(this).closest('tr').removeClass('active');
    //                 }
    //             });

    //             if (checked) {
    //                 for (let i = 0; i < self.allMSCFanCoilLogs.length; i++) {
    //                     self.selectedMscFanCoilLogs[i] =
    //                         self.allMSCFanCoilLogs[i].id;
    //                 }
    //             } else {
    //                 self.selectedMscFanCoilLogs = [];
    //             }
    //         }
    //     );

    //     this.mSCFanCoilDatatable.on(
    //         'change',
    //         'tbody tr .m-checkbox',
    //         function () {
    //             let id = $(this).attr('id');

    //             let isChecked = $(this)
    //                 .parents('tr')
    //                 .find('td:first-child .m-checkable')
    //                 .is(':checked');

    //             if (isChecked) {
    //                 //return index of element, if not found it will return -1
    //                 let found = $.inArray(id, self.selectedMscFanCoilLogs);
    //                 if (found < 0) {
    //                     // Element was not found, add it.
    //                     self.selectedMscFanCoilLogs.push(id);
    //                 }
    //             } else {
    //                 // return index of element, if not found it will return -1
    //                 let found = $.inArray(id, self.selectedMscFanCoilLogs);
    //                 if (found >= 0) {
    //                     // Element was found, remove it.
    //                     self.selectedMscFanCoilLogs.splice(found, 1);
    //                 }
    //             }
    //         }
    //     );

    //     $('#m_table_5').on('click', 'a.m-portlet__nav-link', function () {
    //         let id = $(this).attr('id');
    //         self.DeleteMSCFanCoilLog(id);
    //     });

    //     // this.mSCFanCoilDatatable.on(
    //     //     'init.dt',
    //     //     function () {
    //     //         console.log('datatable init')
    //     //     }
    //     // );
    // }

    // InitMscIrBoilerTable(): void {
    //     let self = this;
    //     // begin first table
    //     this.mSCIRBoilerDatatable = $('#m_table_6').DataTable({
    //         data: this.allMSCIrBoilerLogs,
    //         responsive: true,
    //         // paging: true,
    //         //== DOM Layout settings
    //         dom:
    //             "<'row'<'col-sm-12'f>>" +
    //             "<'row'<'col-sm-12'tr>>" +
    //             "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

    //         pageLength: 10,

    //         language: {
    //             lengthMenu: 'Display _MENU_',
    //         },

    //         //== Order settings
    //         order: [[1, 'desc']],

    //         headerCallback: function (thead, data, start, end, display) {
    //             thead.getElementsByTagName('th')[0].innerHTML = `
    //                   <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
    //                       <input type="checkbox" value="" class="m-group-checkable">
    //                       <span></span>
    //                   </label>`;
    //         },

    //         columnDefs: [
    //             {
    //                 targets: 0,
    //                 data: 'id',
    //                 width: '30px',
    //                 className: 'dt-right',
    //                 orderable: false,
    //                 render: function (data, type, full, meta) {
    //                     return (
    //                         `
    //                       <label id="` +
    //                         full.id +
    //                         `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
    //                           <input type="checkbox" value="" class="m-checkable">
    //                           <span></span>
    //                       </label>`
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 1,
    //                 data: 'mac_address',
    //                 title: 'Mac Address',
    //                 className: 'text-center',
    //             },
    //             {
    //                 targets: 2,
    //                 data: 'mac_address',
    //                 title: 'Name',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + self.get_name(data) + '</div>';
    //                 },
    //             },
    //             {
    //                 targets: 3,
    //                 data: 'timestamp',
    //                 title: 'timestamp',
    //                 render: function (data, type, full, meta) {
    //                     let dateISOString = data;
    //                     let date = new Date(dateISOString);
    //                     date = new Date(
    //                         date.getTime() + date.getTimezoneOffset() * 60000
    //                     );
    //                     // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //                     let options = {};
    //                     return (
    //                         '<span>' +
    //                         date.toLocaleString([], options) +
    //                         '</span>'
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 4,
    //                 data: 'mac_address',
    //                 title: 'Gateway',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + self.get_gateway(data) + '</div>';
    //                 },
    //             },
    //             {
    //                 targets: 5,
    //                 data: 'temperature',
    //                 title: 'Temperature',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '°C</div>';
    //                 },
    //             },
    //             {
    //                 targets: 6,
    //                 data: 'humidity',
    //                 title: 'Humidity',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '%</div>';
    //                 },
    //             },
    //             {
    //                 targets: 7,
    //                 data: 'presence',
    //                 title: 'Presence',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     var status = {
    //                         0: { title: 'Present', class: 'm-badge--success' },
    //                         1: { title: 'Absent', class: ' m-badge--danger' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = data ? 0 : 1;
    //                     return (
    //                         '<span class="m-badge ' +
    //                         status[location].class +
    //                         ' m-badge--wide">' +
    //                         status[location].title +
    //                         '</span>'
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 8,
    //                 data: 'batteryLevel',
    //                 title: 'Battery',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '%</div>';
    //                 },
    //             },
    //             {
    //                 targets: 9,
    //                 data: 'ac_mode',
    //                 title: 'Mode',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'OFF', 'class': 'm-badge--meatl' },
    //                         1: { 'title': 'Cool', 'class': ' m-badge--primary' },
    //                         2: { 'title': 'Heat', 'class': ' m-badge--danger' },
    //                         3: { 'title': 'Fan', 'class': ' m-badge--success' },
    //                         4: { 'title': 'Dry', 'class': ' m-badge--warning' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == self.constantsService.AC_MODE_OFF) {
    //                         location = 0;
    //                     } else if (data == self.constantsService.AC_MODE_COOL) {
    //                         location = 1;
    //                     } else if (data == self.constantsService.AC_MODE_HEAT) {
    //                         location = 2;
    //                     } else if (data == self.constantsService.AC_MODE_FAN) {
    //                         location = 3;
    //                     } else {
    //                         location = 4;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 10,
    //                 data: 'boiler_state',
    //                 title: 'State',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'OFF', 'class': 'm-badge--danger' },
    //                         1: { 'title': 'ON', 'class': ' m-badge--success' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == 0) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 11,
    //                 data: 'boiler_temperature',
    //                 title: 'Boiler Temp',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '°C</div>';
    //                 },
    //             },
    //             {
    //                 targets: 12,
    //                 data: 'ac_temperature',
    //                 title: 'AC Temp',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + data + '°C</div>';
    //                 },
    //             },
    //             {
    //                 targets: 13,
    //                 data: 'enabled',
    //                 title: 'Eanbled',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'Enabled', 'class': 'm-badge--success' },
    //                         1: { 'title': 'Disabled', 'class': ' m-badge--danger' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 14,
    //                 data: 'ac_control',
    //                 title: 'AC Control',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'smart', 'class': ' m-badge--success' },
    //                         1: { 'title': 'manual', 'class': 'm-badge--primary' }
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == self.constantsService.AC_CONTROL_SMART) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 15,
    //                 data: 'boiler_control',
    //                 title: 'Boiler Control',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'smart', 'class': ' m-badge--success' },
    //                         1: { 'title': 'manual', 'class': 'm-badge--primary' }
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == self.constantsService.AC_CONTROL_SMART) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 16,
    //                 width: '100px',
    //                 title: 'Actions',
    //                 orderable: false,
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return (
    //                         `
    //                       <a href="javascript:void(0);" id="` +
    //                         full.id +
    //                         `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
    //                         <i class="la la-trash"></i>
    //                       </a>`
    //                     );
    //                 },
    //             },
    //         ],
    //     });

    //     this.mSCIRBoilerDatatable.on(
    //         'change',
    //         '.m-group-checkable',
    //         function () {
    //             var set = $(this)
    //                 .closest('table')
    //                 .find('td:first-child .m-checkable');
    //             var checked = $(this).is(':checked');

    //             $(set).each(function () {
    //                 if (checked) {
    //                     $(this).prop('checked', true);
    //                     $(this).closest('tr').addClass('active');
    //                 } else {
    //                     $(this).prop('checked', false);
    //                     $(this).closest('tr').removeClass('active');
    //                 }
    //             });

    //             if (checked) {
    //                 for (let i = 0; i < self.allMSCIrBoilerLogs.length; i++) {
    //                     self.selectedMscIrBoilerLogs[i] =
    //                         self.allMSCIrBoilerLogs[i].id;
    //                 }
    //             } else {
    //                 self.selectedMscIrBoilerLogs = [];
    //             }
    //         }
    //     );

    //     this.mSCIRBoilerDatatable.on(
    //         'change',
    //         'tbody tr .m-checkbox',
    //         function () {
    //             let id = $(this).attr('id');

    //             let isChecked = $(this)
    //                 .parents('tr')
    //                 .find('td:first-child .m-checkable')
    //                 .is(':checked');

    //             if (isChecked) {
    //                 //return index of element, if not found it will return -1
    //                 let found = $.inArray(id, self.selectedMscIrBoilerLogs);
    //                 if (found < 0) {
    //                     // Element was not found, add it.
    //                     self.selectedMscIrBoilerLogs.push(id);
    //                 }
    //             } else {
    //                 // return index of element, if not found it will return -1
    //                 let found = $.inArray(id, self.selectedMscIrBoilerLogs);
    //                 if (found >= 0) {
    //                     // Element was found, remove it.
    //                     self.selectedMscIrBoilerLogs.splice(found, 1);
    //                 }
    //             }
    //         }
    //     );

    //     $('#m_table_6').on('click', 'a.m-portlet__nav-link', function () {
    //         let id = $(this).attr('id');
    //         self.DeleteMSCIrBoilerLog(id);
    //     });

    //     // this.mSCIRBoilerDatatable.on(
    //     //     'init.dt',
    //     //     function () {
    //     //         console.log('datatable init')
    //     //     }
    //     // );
    // }

    // InitAcSwitchTable(): void {
    //     let self = this;
    //     // begin first table
    //     this.aCSwitchDatatable = $('#m_table_7').DataTable({
    //         data: this.allacSwitchLogs,
    //         responsive: true,
    //         // paging: true,
    //         //== DOM Layout settings
    //         dom:
    //             "<'row'<'col-sm-12'f>>" +
    //             "<'row'<'col-sm-12'tr>>" +
    //             "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

    //         pageLength: 10,

    //         language: {
    //             lengthMenu: 'Display _MENU_',
    //         },

    //         //== Order settings
    //         order: [[1, 'desc']],

    //         headerCallback: function (thead, data, start, end, display) {
    //             thead.getElementsByTagName('th')[0].innerHTML = `
    //                   <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
    //                       <input type="checkbox" value="" class="m-group-checkable">
    //                       <span></span>
    //                   </label>`;
    //         },

    //         columnDefs: [
    //             {
    //                 targets: 0,
    //                 data: 'id',
    //                 width: '30px',
    //                 className: 'dt-right',
    //                 orderable: false,
    //                 render: function (data, type, full, meta) {
    //                     return (
    //                         `
    //                       <label id="` +
    //                         full.id +
    //                         `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
    //                           <input type="checkbox" value="" class="m-checkable">
    //                           <span></span>
    //                       </label>`
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 1,
    //                 data: 'mac_address',
    //                 title: 'Mac Address',
    //                 className: 'text-center',
    //             },
    //             {
    //                 targets: 2,
    //                 data: 'mac_address',
    //                 title: 'Name',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + self.get_name(data) + '</div>';
    //                 },
    //             },
    //             {
    //                 targets: 3,
    //                 data: 'timestamp',
    //                 title: 'timestamp',
    //                 render: function (data, type, full, meta) {
    //                     let dateISOString = data;
    //                     let date = new Date(dateISOString);
    //                     date = new Date(
    //                         date.getTime() + date.getTimezoneOffset() * 60000
    //                     );
    //                     // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //                     let options = {};
    //                     return (
    //                         '<span>' +
    //                         date.toLocaleString([], options) +
    //                         '</span>'
    //                     );
    //                 },
    //             },
    //             {
    //                 targets: 4,
    //                 data: 'mac_address',
    //                 title: 'Gateway',
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return '<div>' + self.get_gateway(data) + '</div>';
    //                 },
    //             },
    //             {
    //                 targets: 5,
    //                 data: 'enabled',
    //                 title: 'Eanbled',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'Enabled', 'class': 'm-badge--success' },
    //                         1: { 'title': 'Disabled', 'class': ' m-badge--danger' },
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 6,
    //                 data: 'ac_control',
    //                 title: 'Control',
    //                 className: "text-center",
    //                 render: function(data, type, full, meta) {
    //                     var status = {
    //                         0: { 'title': 'smart', 'class': ' m-badge--success' },
    //                         1: { 'title': 'manual', 'class': 'm-badge--primary' }
    //                     };
    //                     // if (typeof status[data] === 'undefined') {
    //                     //     return data;
    //                     // }
    //                     let location: number = 0;
    //                     if (data == self.constantsService.AC_CONTROL_SMART) {
    //                         location = 0;
    //                     } else {
    //                         location = 1;
    //                     }
    //                     return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
    //                 },
    //             },
    //             {
    //                 targets: 7,
    //                 width: '100px',
    //                 title: 'Actions',
    //                 orderable: false,
    //                 className: 'text-center',
    //                 render: function (data, type, full, meta) {
    //                     return (
    //                         `
    //                       <a href="javascript:void(0);" id="` +
    //                         full.id +
    //                         `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
    //                         <i class="la la-trash"></i>
    //                       </a>`
    //                     );
    //                 },
    //             },
    //         ],
    //     });

    //     this.aCSwitchDatatable.on(
    //         'change',
    //         '.m-group-checkable',
    //         function () {
    //             var set = $(this)
    //                 .closest('table')
    //                 .find('td:first-child .m-checkable');
    //             var checked = $(this).is(':checked');

    //             $(set).each(function () {
    //                 if (checked) {
    //                     $(this).prop('checked', true);
    //                     $(this).closest('tr').addClass('active');
    //                 } else {
    //                     $(this).prop('checked', false);
    //                     $(this).closest('tr').removeClass('active');
    //                 }
    //             });

    //             if (checked) {
    //                 for (let i = 0; i < self.allacSwitchLogs.length; i++) {
    //                     self.selectedAcSwitchLogs[i] =
    //                         self.allacSwitchLogs[i].id;
    //                 }
    //             } else {
    //                 self.selectedAcSwitchLogs = [];
    //             }
    //         }
    //     );

    //     this.aCSwitchDatatable.on(
    //         'change',
    //         'tbody tr .m-checkbox',
    //         function () {
    //             let id = $(this).attr('id');

    //             let isChecked = $(this)
    //                 .parents('tr')
    //                 .find('td:first-child .m-checkable')
    //                 .is(':checked');

    //             if (isChecked) {
    //                 //return index of element, if not found it will return -1
    //                 let found = $.inArray(id, self.selectedAcSwitchLogs);
    //                 if (found < 0) {
    //                     // Element was not found, add it.
    //                     self.selectedAcSwitchLogs.push(id);
    //                 }
    //             } else {
    //                 // return index of element, if not found it will return -1
    //                 let found = $.inArray(id, self.selectedAcSwitchLogs);
    //                 if (found >= 0) {
    //                     // Element was found, remove it.
    //                     self.selectedAcSwitchLogs.splice(found, 1);
    //                 }
    //             }
    //         }
    //     );

    //     $('#m_table_7').on('click', 'a.m-portlet__nav-link', function () {
    //         let id = $(this).attr('id');
    //         self.DeleteAcSwitchLog(id);
    //     });

    //     // this.aCSwitchDatatable.on(
    //     //     'init.dt',
    //     //     function () {
    //     //         console.log('datatable init')
    //     //     }
    //     // );
    // }

    public DeleteRoomSensorLog(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover log again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_1', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService.deleteRoomSensorLog(id).subscribe(
                    (response) => {
                        self.getRoomSensorLog();
                        // Swal(
                        //     'Deleted!',
                        //     'Log has been deleted.',
                        //     'success'
                        // );
                    },
                    (err) => {
                        self.getRoomSensorLog();
                        Swal('Cancelled', err._body, 'error');
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteRoomSensorLogs(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those readings again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_1', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService
                    .deleteRoomSensorLogs(self.selectedRoomSensorLogs)
                    .subscribe(
                        (response) => {
                            self.getRoomSensorLog();
                            // Swal(
                            //     'Deleted!',
                            //     'Reading has been deleted.',
                            //     'success'
                            // );
                        },
                        (err) => {
                            self.getRoomSensorLog();
                            Swal(
                                'Cancelled',
                                'Error occured and readings were not deleted',
                                'error'
                            );
                        }
                    );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCLog(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover log again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_2', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService.deleteMscLog(id).subscribe(
                    (response) => {
                        self.getMSCLog();
                        // Swal(
                        //     'Deleted!',
                        //     'Log has been deleted.',
                        //     'success'
                        // );
                    },
                    (err) => {
                        self.getMSCLog();
                        Swal('Cancelled', err._body, 'error');
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCLogs(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those readings again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_2', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService
                    .deleteMscLogs(self.selectedMscLogs)
                    .subscribe(
                        (response) => {
                            self.getMSCLog();
                            // Swal(
                            //     'Deleted!',
                            //     'Reading has been deleted.',
                            //     'success'
                            // );
                        },
                        (err) => {
                            self.getMSCLog();
                            Swal(
                                'Cancelled',
                                'Error occured and readings were not deleted',
                                'error'
                            );
                        }
                    );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCBoilerLog(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover log again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_3', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService.deleteMSCBoilerLog(id).subscribe(
                    (response) => {
                        self.getMscBoilerLog();
                        // Swal(
                        //     'Deleted!',
                        //     'Log has been deleted.',
                        //     'success'
                        // );
                    },
                    (err) => {
                        self.getMscBoilerLog();
                        Swal('Cancelled', err._body, 'error');
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCBoilerLogs(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those readings again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_3', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService
                    .deleteMSCBoilerLogs(self.selectedMscBoilerLogs)
                    .subscribe(
                        (response) => {
                            self.getMscBoilerLog();
                            // Swal(
                            //     'Deleted!',
                            //     'Reading has been deleted.',
                            //     'success'
                            // );
                        },
                        (err) => {
                            self.getMscBoilerLog();
                            Swal(
                                'Cancelled',
                                'Error occured and readings were not deleted',
                                'error'
                            );
                        }
                    );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCAirDuctLog(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover log again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_4', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService.deleteMSCAirDuctLog(id).subscribe(
                    (response) => {
                        self.getMscAirDuctLog();
                        // Swal(
                        //     'Deleted!',
                        //     'Log has been deleted.',
                        //     'success'
                        // );
                    },
                    (err) => {
                        self.getMscAirDuctLog();
                        Swal('Cancelled', err._body, 'error');
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCAirDuctLogs(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those readings again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_4', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.reportService
                    .deleteMSCAirDuctLogs(self.selectedMscAirDuctLogs)
                    .subscribe(
                        (response) => {
                            self.getMscAirDuctLog();
                            // Swal(
                            //     'Deleted!',
                            //     'Reading has been deleted.',
                            //     'success'
                            // );
                        },
                        (err) => {
                            self.getMscAirDuctLog();
                            Swal(
                                'Cancelled',
                                'Error occured and readings were not deleted',
                                'error'
                            );
                        }
                    );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    // public DeleteMSCFanCoilLog(id: string): void {
    //     if (!this.isAdmin) {
    //         Swal(
    //             'Unauthorized',
    //             'You are not allowed to perform this operation',
    //             'error'
    //         );

    //         return;
    //     }
    //     let self = this;
    //     Swal({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover log again!',
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, keep it',
    //     }).then((result) => {
    //         if (result.value) {
    //             mApp.block('#m_table_5', {
    //                 overlayColor: '#000000',
    //                 type: 'loader',
    //                 state: 'success',
    //                 message: 'Please wait...',
    //             });
    //             self.reportService.deleteMSCFanCoilLog(id).subscribe(
    //                 (response) => {
    //                     self.getMscFanCoilLog();
    //                     // Swal(
    //                     //     'Deleted!',
    //                     //     'Log has been deleted.',
    //                     //     'success'
    //                     // );
    //                 },
    //                 (err) => {
    //                     self.getMscFanCoilLog();
    //                     Swal('Cancelled', err._body, 'error');
    //                 }
    //             );
    //             // For more information about handling dismissals please visit
    //             // https://sweetalert2.github.io/#handling-dismissals
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             // Swal(
    //             //     'Cancelled',
    //             //     'Your imaginary file is safe :',
    //             //     'error'
    //             // )
    //         }
    //     });
    // }

    // public DeleteMSCFanCoilLogs(): void {
    //     if (!this.isAdmin) {
    //         Swal(
    //             'Unauthorized',
    //             'You are not allowed to perform this operation',
    //             'error'
    //         );

    //         return;
    //     }
    //     let self = this;
    //     Swal({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover those readings again!',
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, keep it',
    //     }).then((result) => {
    //         if (result.value) {
    //             mApp.block('#m_table_5', {
    //                 overlayColor: '#000000',
    //                 type: 'loader',
    //                 state: 'success',
    //                 message: 'Please wait...',
    //             });
    //             self.reportService
    //                 .deleteMSCFanCoilLogs(self.selectedMscFanCoilLogs)
    //                 .subscribe(
    //                     (response) => {
    //                         self.getMscFanCoilLog();
    //                         // Swal(
    //                         //     'Deleted!',
    //                         //     'Reading has been deleted.',
    //                         //     'success'
    //                         // );
    //                     },
    //                     (err) => {
    //                         self.getMscFanCoilLog();
    //                         Swal(
    //                             'Cancelled',
    //                             'Error occured and readings were not deleted',
    //                             'error'
    //                         );
    //                     }
    //                 );
    //             // For more information about handling dismissals please visit
    //             // https://sweetalert2.github.io/#handling-dismissals
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             // Swal(
    //             //     'Cancelled',
    //             //     'Your imaginary file is safe :',
    //             //     'error'
    //             // )
    //         }
    //     });
    // }

    // public DeleteMSCIrBoilerLog(id: string): void {
    //     if (!this.isAdmin) {
    //         Swal(
    //             'Unauthorized',
    //             'You are not allowed to perform this operation',
    //             'error'
    //         );

    //         return;
    //     }
    //     let self = this;
    //     Swal({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover log again!',
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, keep it',
    //     }).then((result) => {
    //         if (result.value) {
    //             mApp.block('#m_table_6', {
    //                 overlayColor: '#000000',
    //                 type: 'loader',
    //                 state: 'success',
    //                 message: 'Please wait...',
    //             });
    //             self.reportService.deleteMSCIrBoilerLog(id).subscribe(
    //                 (response) => {
    //                     self.getMscIrBoilerLog();
    //                     // Swal(
    //                     //     'Deleted!',
    //                     //     'Log has been deleted.',
    //                     //     'success'
    //                     // );
    //                 },
    //                 (err) => {
    //                     self.getMscIrBoilerLog();
    //                     Swal('Cancelled', err._body, 'error');
    //                 }
    //             );
    //             // For more information about handling dismissals please visit
    //             // https://sweetalert2.github.io/#handling-dismissals
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             // Swal(
    //             //     'Cancelled',
    //             //     'Your imaginary file is safe :',
    //             //     'error'
    //             // )
    //         }
    //     });
    // }

    // public DeleteMSCIrBoilerLogs(): void {
    //     if (!this.isAdmin) {
    //         Swal(
    //             'Unauthorized',
    //             'You are not allowed to perform this operation',
    //             'error'
    //         );

    //         return;
    //     }
    //     let self = this;
    //     Swal({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover those readings again!',
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, keep it',
    //     }).then((result) => {
    //         if (result.value) {
    //             mApp.block('#m_table_6', {
    //                 overlayColor: '#000000',
    //                 type: 'loader',
    //                 state: 'success',
    //                 message: 'Please wait...',
    //             });
    //             self.reportService
    //                 .deleteMSCIrBoilerLogs(self.selectedMscIrBoilerLogs)
    //                 .subscribe(
    //                     (response) => {
    //                         self.getMscIrBoilerLog();
    //                         // Swal(
    //                         //     'Deleted!',
    //                         //     'Reading has been deleted.',
    //                         //     'success'
    //                         // );
    //                     },
    //                     (err) => {
    //                         self.getMscIrBoilerLog();
    //                         Swal(
    //                             'Cancelled',
    //                             'Error occured and readings were not deleted',
    //                             'error'
    //                         );
    //                     }
    //                 );
    //             // For more information about handling dismissals please visit
    //             // https://sweetalert2.github.io/#handling-dismissals
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             // Swal(
    //             //     'Cancelled',
    //             //     'Your imaginary file is safe :',
    //             //     'error'
    //             // )
    //         }
    //     });
    // }

    // public DeleteAcSwitchLog(id: string): void {
    //     if (!this.isAdmin) {
    //         Swal(
    //             'Unauthorized',
    //             'You are not allowed to perform this operation',
    //             'error'
    //         );

    //         return;
    //     }
    //     let self = this;
    //     Swal({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover log again!',
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, keep it',
    //     }).then((result) => {
    //         if (result.value) {
    //             mApp.block('#m_table_7', {
    //                 overlayColor: '#000000',
    //                 type: 'loader',
    //                 state: 'success',
    //                 message: 'Please wait...',
    //             });
    //             self.reportService.deleteAcSwitchLog(id).subscribe(
    //                 (response) => {
    //                     self.getAcSwitchLog();
    //                     // Swal(
    //                     //     'Deleted!',
    //                     //     'Log has been deleted.',
    //                     //     'success'
    //                     // );
    //                 },
    //                 (err) => {
    //                     self.getAcSwitchLog();
    //                     Swal('Cancelled', err._body, 'error');
    //                 }
    //             );
    //             // For more information about handling dismissals please visit
    //             // https://sweetalert2.github.io/#handling-dismissals
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             // Swal(
    //             //     'Cancelled',
    //             //     'Your imaginary file is safe :',
    //             //     'error'
    //             // )
    //         }
    //     });
    // }

    // public DeleteAcSwitchLogs(): void {
    //     if (!this.isAdmin) {
    //         Swal(
    //             'Unauthorized',
    //             'You are not allowed to perform this operation',
    //             'error'
    //         );

    //         return;
    //     }
    //     let self = this;
    //     Swal({
    //         title: 'Are you sure?',
    //         text: 'You will not be able to recover those readings again!',
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, keep it',
    //     }).then((result) => {
    //         if (result.value) {
    //             mApp.block('#m_table_7', {
    //                 overlayColor: '#000000',
    //                 type: 'loader',
    //                 state: 'success',
    //                 message: 'Please wait...',
    //             });
    //             self.reportService
    //                 .deleteAcSwitchLogs(self.selectedAcSwitchLogs)
    //                 .subscribe(
    //                     (response) => {
    //                         self.getAcSwitchLog();
    //                         // Swal(
    //                         //     'Deleted!',
    //                         //     'Reading has been deleted.',
    //                         //     'success'
    //                         // );
    //                     },
    //                     (err) => {
    //                         self.getAcSwitchLog();
    //                         Swal(
    //                             'Cancelled',
    //                             'Error occured and readings were not deleted',
    //                             'error'
    //                         );
    //                     }
    //                 );
    //             // For more information about handling dismissals please visit
    //             // https://sweetalert2.github.io/#handling-dismissals
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             // Swal(
    //             //     'Cancelled',
    //             //     'Your imaginary file is safe :',
    //             //     'error'
    //             // )
    //         }
    //     });
    // }

    checkChartToggle(event: any) {
        if (this.toggle_chart) {
            $('#m_table_1_parent').hide();
            $('#m_table_2_parent').hide();
            $('#m_table_3_parent').hide();
            $('#m_table_4_parent').hide();
            $('#m_table_5_parent').hide();
            $('#m_table_6_parent').hide();
            $('#m_table_7_parent').hide();
        } else {
            $('#m_table_1_parent').show();
            $('#m_table_2_parent').show();
            $('#m_table_3_parent').show();
            $('#m_table_4_parent').show();
            $('#m_table_5_parent').show();
            $('#m_table_6_parent').show();
            $('#m_table_7_parent').show();
        }
    }
}
