import { Component, OnInit } from '@angular/core';
import { CleandbService } from '../../services/cleandb/cleandb.service';

declare var $: any;
import Swal from 'sweetalert2';

@Component({
    selector: 'app-profile',
    templateUrl: './clean_server.component.html',
    styleUrls: ['./clean_server.component.css'],
})
export class CleanServerComponent implements OnInit {
    constructor(private cleandbService: CleandbService) {}

    ngOnInit() {}

    clean_charts() {
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr(
            'disabled',
            true
        );
        this.cleandbService.deleteCharts(true).subscribe(
            (response) => {
                // if reached here, it means the response is success
                btn.removeClass(
                    'm-loader m-loader--right m-loader--light'
                ).attr('disabled', false);
                Swal(
                    'Deleting Charts',
                    'Charts on backend has been cleared',
                    'info'
                );
            },
            (err) => {
                btn.removeClass(
                    'm-loader m-loader--right m-loader--light'
                ).attr('disabled', false);
            }
        );
    }
}
