import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { UtilsService } from "../../services/utils/utils.service";
import { UserService } from "../../services/user/user.service";

declare var $: any;

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  public password: string = "";
  public repeat_password: string = "";
  private user_id: string = "";
  private reset_token: string = "";
  private unsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private utilsService: UtilsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    // Update the background
    $(document.body).removeClass();
    $(document.body).addClass(
      "m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
    );
    //$('#body').css("background-image", "url(src/assets/metronic/app/media/img/bg/bg-2.jpg)");

    let subscription = this.activatedroute.paramMap.subscribe((params) => {
      this.user_id = params.get("user_id");
      this.reset_token = params.get("reset_token");
    });
    this.unsubscribe.push(subscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
    $(document.body).removeClass();
    // this.renderer.removeClass(document.body, 'm--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');
  }

  private showErrorMsg(formId: string, type: string, msg: string) {
    let form = $(formId).closest("form");
    let alert = $(
      '<div class="m-alert m-alert--outline alert alert-' +
        type +
        ' alert-dismissible" role="alert">\
                   <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>\
                   <span></span>\
                   </div>'
    );

    form.find(".alert").remove();
    alert.prependTo(form);
    this.utilsService.animate(alert, "fadeIn animated");
    alert.find("span").html(msg);
  }

  public submit_reset_password() {
    if (this.password != this.repeat_password) {
      this.showErrorMsg(
        "#m_login_signin_submit",
        "danger",
        "Passwords are not matching."
      );
    } else {
      // console.log(this.password);
      // console.log(this.repeat_password);
      // console.log(this.user_id);
      // console.log(this.reset_token);
      this.userService
        .verify_reset_password(this.user_id, this.reset_token, this.password)
        .subscribe(
          (response) => {
            this.password = '';
            this.repeat_password = '';
            this.router.navigateByUrl('/login');
          },
          (err) => {
            // if reached here, it means the response is error();
            this.password = '';
            this.repeat_password = '';
            this.router.navigateByUrl('/login');
          }
        );
    }
  }
}
