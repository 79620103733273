import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { CleandbService } from '../../services/cleandb/cleandb.service';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-properties',
    templateUrl: './properties.component.html',
    styleUrls: ['./properties.component.css'],
})
export class PropertiesComponent implements OnInit, AfterViewInit {
    public allClients: any[] = [];
    private clientsLoaded: boolean = false;
    public readings: any[] = [];
    private datatable: any;

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(
        private clientService: ClientService,
        private cleandbService: CleandbService
    ) {}

    ngOnInit() {
        mApp.blockPage();

        // basic
        $('#m_select2_1').select2({
            placeholder: 'Select AC mode',
            allowClear: false,
            width: '100%',
        });

        this.getAllClients();
    }

    ngAfterViewInit() {
        this.ngForList.changes.subscribe((t) => {
            this.ngForRendredCallback();
        });
    }

    ngForRendredCallback() {
        if (!this.clientsLoaded) {
            $('#m_select2_2').select2({
                placeholder: 'Select client',
                allowClear: true,
                width: '100%',
            });
            $('#m_select2_2').val(null).trigger('change'); // this will set selection to first item
        }

        $('#m_select2_2').on('select2:select', function (e) {
            // var data = e.params.data;
        });
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                mApp.unblockPage();
            }
        );
    }

    submit() {
        this.readings = [];
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr(
            'disabled',
            true
        );
        let client_name = $('#m_select2_2').select2('data')[0];

        if (client_name) {
            client_name = client_name.text;
            client_name = client_name.substring(1, client_name.length - 1);
            console.log(client_name);
        } else {
            client_name = null;
        }

        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...',
        });

        // get properties the client
        this.cleandbService.getProperties(client_name).subscribe(
            (response) => {
                // if reached here, it means the response is success
                btn.removeClass(
                    'm-loader m-loader--right m-loader--light'
                ).attr('disabled', false);
                this.readings = response;

                console.log(this.readings);

                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            },
            (err) => {
                btn.removeClass(
                    'm-loader m-loader--right m-loader--light'
                ).attr('disabled', false);
                this.readings = [];

                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            }
        );
    }

    cancel() {
        $('#m_select2_2').val(null).trigger('change'); // this will set selection to no item
    }

    InitTable(): void {
        let self = this;
        // begin first table
        this.datatable = $('#m_table_1').DataTable({
            data: this.readings,
            responsive: true,
            paging: true,
            //== DOM Layout settings
            dom:
                "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                lengthMenu: 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            columnDefs: [
                {
                    targets: 0,
                    data: 'client_name',
                    title: 'Client',
                    className: 'text-center',
                },
                {
                    targets: 1,
                    data: 'apartment_name',
                    title: 'Apartment',
                    className: 'text-center',
                },
                {
                    targets: 2,
                    data: 'room_name',
                    title: 'Room',
                    className: 'text-center',
                },
                {
                    targets: 3,
                    data: 'device_type',
                    title: 'Device',
                    className: 'text-center',
                },
                {
                    targets: 4,
                    data: 'mac_address',
                    title: 'MAC',
                    className: 'text-center',
                },
                {
                    targets: 5,
                    data: 'state',
                    title: 'State',
                    className: 'text-center',
                },
                {
                    targets: 6,
                    data: 'mode',
                    title: 'Mode',
                    className: 'text-center',
                },
                {
                    targets: 7,
                    data: 'control_mode',
                    title: 'Control',
                    className: 'text-center',
                },
                {
                    targets: 8,
                    data: 'night_mode',
                    title: 'Night',
                    className: 'text-center',
                },
                {
                    targets: 9,
                    data: 'rssi',
                    title: 'RSSI',
                    className: 'text-center',
                },
                {
                    targets: 10,
                    data: 'batteryLevel',
                    title: 'battery',
                    className: 'text-center',
                },
            ],
        });
    }
}
