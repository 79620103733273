import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { CleandbService } from '../../services/cleandb/cleandb.service';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-broadcast-mode',
    templateUrl: './broadcast-mode.component.html',
    styleUrls: ['./broadcast-mode.component.css'],
})
export class BroadcastModeComponent implements OnInit, AfterViewInit {
    public allClients: any[] = [];
    private clientsLoaded: boolean = false;
    public msc_timeout: string = '15';

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(private clientService: ClientService, private cleandbService: CleandbService) {}

    ngOnInit() {
        mApp.blockPage();

        // basic
        $('#m_select2_1').select2({
            placeholder: 'Select AC mode',
            allowClear: false,
            width: '100%',
        });

        // basic
        $('#m_select2_3').select2({
            placeholder: 'Select night mode',
            allowClear: false,
            width: '100%',
        });

        // minimum setup
        $('#m_touchspin_2').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 5,
            max: 10000,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        this.getAllClients();
    }

    ngAfterViewInit() {
        this.ngForList.changes.subscribe((t) => {
            this.ngForRendredCallback();
        });
    }

    ngForRendredCallback() {
        if (!this.clientsLoaded) {
            $('#m_select2_2').select2({
                placeholder: 'Select client',
                allowClear: true,
                width: '100%',
            });
            $('#m_select2_2').val(null).trigger('change'); // this will set selection to first item
        }

        $('#m_select2_2').on('select2:select', function (e) {
            // var data = e.params.data;
        });
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                mApp.unblockPage();
            }
        );
    }

    submit() {
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let mode = $('#m_select2_1').select2('data')[0];
        let client_name = $('#m_select2_2').select2('data')[0];

        var client_id = null;
        if (client_name) {
            client_id = this.getCientIdFromName(client_name.text);
        }

        var mode_str = '0';
        if (mode.text == 'Cool') {
            mode_str = '0';
        } else if (mode.text == 'Heat') {
            mode_str = '1';
        }

        //   console.log(mode);
        //   console.log(client_id);


        // post the client
        this.cleandbService.postBroadcastMode(client_id, mode_str).subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.cancel();
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            },
            (err) => {
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            });
    }

    submit_night_mode() {
        let btn = $('#submit_night_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let nigh_mode = $('#m_select2_3').select2('data')[0];
        let client_name = $('#m_select2_2').select2('data')[0];

        var client_id = null;
        if (client_name) {
            client_id = this.getCientIdFromName(client_name.text);
        }

        var night_mode_str = '0';
        if (nigh_mode.text == 'OFF') {
            night_mode_str = '0';
        } else if (nigh_mode.text == 'ON') {
            night_mode_str = '1';
        }

        //   console.log(mode);
        //   console.log(client_id);


        // post the client
        this.cleandbService.postBroadcastNightMode(client_id, night_mode_str).subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.cancel();
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            },
            (err) => {
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            });
    }

    reboot_msc() {
        let btn = $('#reboot_msc_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let client_name = $('#m_select2_2').select2('data')[0];
        if (client_name) {
            client_name = client_name.text;
            client_name = client_name.substring(0, client_name.length - 1);
        }        

        // post the client
        this.cleandbService.postRebootMSC(client_name).subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.cancel();
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            },
            (err) => {
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            });
    }

    restart_gateway() {
        let btn = $('#restart_gateway_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let client_name = $('#m_select2_2').select2('data')[0];
        if (client_name) {
            client_name = client_name.text;
            client_name = client_name.substring(0, client_name.length - 1);
        }

        // post the client
        this.cleandbService.postRestartGateway(client_name).subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.cancel();
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            },
            (err) => {
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
            });
    }

    submit_timeout() {
        let btn = $('#submit_msc_timeout');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let timeout = $('#m_touchspin_2').val();
        let client_name = $('#m_select2_2').select2('data')[0];

        var client_id = null;
        if (client_name) {
            client_id = this.getCientIdFromName(client_name.text);
        }


        // console.log(timeout);
        // console.log(client_id);


        // post the client
        this.cleandbService.postMSCTimeout(client_id, timeout).subscribe(
        (response) => {
            // if reached here, it means the response is success
            this.cancel();
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        },
        (err) => {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        });
    }

    cancel() {
        $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item
        $('#m_select2_2').val(null).trigger('change'); // this will set selection to no item
    }

    getCientIdFromName(client_name: string) {
        client_name = client_name.substring(0, client_name.length - 1);
        for (let i = 0; i < this.allClients.length; i++) {
            if (this.allClients[i].clientName == client_name) {
                return this.allClients[i].clientId;
            }
        }
        return null;
    }
}
